import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import {
    LOAD_VIDEOLIST,
    setCurrentVideolist, sortVideos
} from '../../store/ContentVideolists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { buildGETUrl } from "../../utilities/buildGETUrl";

export default function* () {
    yield takeEvery(LOAD_VIDEOLIST, worker)
}

function* worker({ payload }) {
    if (!payload) {
        yield put(setCurrentVideolist({ videolist: undefined }))        
        return
    }


    try {
        const fetchedPlaylist = yield call(fetchSongs, payload)

        if (fetchedPlaylist.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_VIDEOLIST}.`, fetchedPlaylist],
                text: "Треки не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(setCurrentVideolist({ videolist: undefined }))
        } else {
            const fpl = fetchedPlaylist.success[0];
            fpl.songs = Object.values(fetchedPlaylist.success.songs).map(song => {
                song.filename = buildGETUrl("clip/play", { id: song.id })//"/clip/play?" + new URLSearchParams({id: song.id});
                //song.download = "https://a.ms-box.ru/clip/download?" + new URLSearchParams({id: song.id});
                return song;
            });
            console.log({fpl});
            yield put(setCurrentVideolist({ videolist: fpl }))
            yield put(sortVideos({ direction: 'down', sort_type: 'id', id: payload.current_videolist }))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_VIDEOLIST}.`, e],
            text: "Треки не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(setCurrentVideolist({ videolist: undefined }))
    } finally {
        // yield put(loading(false))
    }
}

function fetchSongs(playlistId) {
    return axios.get("/videolist/get", {
        params: { id: playlistId },
    }).then(response => response.data)
}