import { takeEvery, call, put, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import {
    LOAD_OBJECTS,
    loading,
    putObjects,
    putObjectsAssociated,
    putObjectsStatusList, toggleSelectedObject
} from '../../store/Objects/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import React from "react";
import { changeValue, modeActiveOn, reloadValue } from "../../store/Search/actions";
import { toggleSelectedObjectState } from "../../store/Reports/actions";

export default function* () {
    yield takeEvery(LOAD_OBJECTS, worker)
}

function* worker() {
    yield put(loading(true))
    let statusList = yield select(state => state.objects.statusList)
    try {
        if (!statusList.length) {

            // try {
            statusList = yield call(fetchStatusList)
            statusList = statusList.success || []
            yield put(putObjectsStatusList(statusList))
            // }
            // catch (e) {
            //     statusList.success = []                    
            // }

        }
        console.log({ statusList });

        const statusObject = statusList.reduce((acc, current) => {
            acc[current.id] = current.name;
            return acc;
        }, {})

        console.log({ statusList });

        let objects = yield call(fetchObjects);


        if (objects.error && objects.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_OBJECTS}.`, objects],
                text: "Объекты не были загружены. " + objects.error
            }))
            yield put(putObjects([]))
        } else {
            let objectsSorted = objectSort(objects.success, statusObject)

            //console.log(objectsSorted)
            yield put(putObjects(objectsSorted))
            yield put(putObjectsAssociated(formatToAssocoatedObjects(objectsSorted)))
            // let params = new URLSearchParams(document.location.search)
            // let msId = params.get("search[ms_id]")
            let msId = (window.localStorage.getItem('search_ms_id'));
            if (msId) {
                yield put(modeActiveOn('objects'))
                yield put(changeValue({ type: 'objects', value: msId }))
                yield put(toggleSelectedObject({ selected: [+msId], simple: true }))
                yield put(toggleSelectedObjectState({ selected: [+msId], simple: true }))
                window.localStorage.removeItem('search_ms_id')
                yield put(reloadValue())
            }
        }
    } catch (e) {
        console.log('>>',{e});
        
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_OBJECTS}.`, e],
            text: "Объекты не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putObjects([]))
    } finally {
        yield put(loading(false))
    }
}

function fetchObjects() {
    return axios.get("/msbox/select")
        .then(response => response.data)
}

function fetchStatusList() {
    return axios.get("/msbox/statuses/select")
        .then(response => response.data)
        .catch(error => {
            console.log("Ошибка доступа к статусам:", error)
            return { success: [] } // Возвращаем пустой массив, если запрос не удался
        })
}

function formatToAssocoatedObjects(objects = []) {
    let answer = {}

    if (Array.isArray(objects)) {
        objects.map(o => answer[o.id] = o)
    }
    return answer
}

export function objectSort(objectsSorted, statusObject = {}) {
    objectsSorted = objectsSorted.map(object => {
        const statusText = object.msstatus_id && typeof statusObject[object.msstatus_id] !== 'undefined' ? statusObject[object.msstatus_id] : '';
        object.statusText = statusText
        object.fullName = (object.name && object.address && object.alias) ? object.alias : `${object.name} ${object.address || ''}`
        return object
    })
    const sortByFullname = (a, b) => a.fullName < b.fullName ? -1 : 1
    const arr1 = objectsSorted.filter(obj => obj.msstatus_id === null).sort(sortByFullname);
    const arr2 = objectsSorted.filter(obj => obj.msstatus_id !== null).sort((a, b) => {
        return (a.msstatus_id < b.msstatus_id) ? -1 : (a.msstatus_id === b.msstatus_id ? (a.fullName < b.fullName ? -1 : 1) : 1)
    });
    objectsSorted = [...arr1, ...arr2];
    objectsSorted = objectsSorted.map(object => {
        // object.fullName = (object.statusText ? (`<span class="badge badge-info">${object.statusText}</span> `) : '' ) + object.id + ' ' + object.fullName;
        object.fullName = object.id + ' ' + object.fullName;
        return object;
    })
    return objectsSorted
}