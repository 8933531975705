import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { openModal } from '../../../store/Modal/actions'
import { formatSecondsToTime } from '../../../utilities/format'
import { cl } from '../../../utilities/cl'

import List from '../List'

import SvgPlayVideo from '../../SVG/PlayVideo'
import SvgPlay from '../../SVG/Play'
import SvgPause from '../../SVG/Pause'
import SvgWarning from '../../SVG/Warning'


class Track extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            played: false,
            currentTime: "00:00",
            slider: "100%",
            listLoaded: false,
            opened: false
        }

        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.rewind = this.rewind.bind(this)
        this.toggleOpen = this.toggleOpen.bind(this)

        this.watcherInterval = null
        this.startWatch = this.startWatch.bind(this)
        this.killWatcher = this.killWatcher.bind(this)
        this.updateTime = this.updateTime.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isWatching !== this.props.isWatching || prevProps.trackPlaying !== this.props.trackPlaying) {
            this.props.isWatching
                ? this.startWatch()
                : this.killWatcher()

        }
    }

    startWatch() {
        const {
            id,
            trackPlaying,
            audio
        } = this.props

        if (trackPlaying === id) {
            this.watcherInterval = setInterval(() => {
                if (audio.currentTime === audio.duration) {
                    this.props.stop()
                } else {
                    this.updateTime()
                }
            }, 50)
        } else {

            this.killWatcher(true)
        }
    }

    killWatcher(resetTime) {
        clearInterval(this.watcherInterval)
        this.updateTime(resetTime)
    }

    updateTime(resetTime) {
        const { trackPlaying, id, audio } = this.props
        let currentTime = "00:00",
            slider = "100%"

        if (resetTime !== true && (audio.currentTime !== audio.duration && trackPlaying === id)) {
            // Время
            currentTime = formatSecondsToTime(audio.currentTime)

            // Слайдер
            const sliderPercent = (audio.currentTime / (audio.duration / 100))
            slider = `${100 - sliderPercent}%`
        }

        this.setState({ currentTime, slider })
    }

    play() {
        const {
            id,
            name,
            filename,
            mimetype,
            play,
            pause,
            openModal,
        } = this.props

        const isAudio = (mimetype.indexOf("mp3") !== -1 || mimetype.indexOf("audio") !== -1)
        const isVideo = (mimetype.indexOf("mp4") !== -1 || mimetype.indexOf("video") !== -1)

        // Аудио
        if (isAudio) {
            play({
                trackId: id,
                filename: filename
            })

            // Видео
        } else if (isVideo) {
            pause()
            openModal({
                type: "video",
                data: {
                    video: {
                        title: name,
                        mp4: filename,
                    }
                }
            })
        }

    }

    pause() {
        this.props.pause()
    }

    rewind(e) {
        const rect = e.target.getBoundingClientRect(),
            clickPercent = 100 * (e.nativeEvent.offsetX / rect.width),
            totime = this.props.audio.duration * (clickPercent * 0.01)

        this.props.rewind(totime)
    }

    toggleOpen() {
        this.setState(state => ({
            listLoaded: true,
            opened: !state.opened
        }))
    }

    render() {
        let {
            className = "",
            variant,
            clipEnded,
            clipDeleted,
            trackIsNew,
            trackWarning,
            trackPlaying,
            isPlaying,
            disabled,
            color,
            id,
            name,
            ctime_d,
            ctime_t,
            mimetype,
            selected,
            markItems,
            cliptime = "00:00:00",
            cliptimeArr,
            pri
        } = this.props

        const {
            slider,
            currentTime,
            opened,
        } = this.state

        className = cl(
            className,
            {
                "Track": [
                    variant,
                ]
            },
            {
                "selectable-item": [
                    "m-top",
                ]
            },
            { "opened": opened },
            { "playing": (isPlaying && trackPlaying === id) },
            { "selected": (selected.indexOf(id) !== -1) },
            { "highlighted": (markItems.indexOf(id) !== -1) },
            { "disabled": disabled },
            { "warning": trackWarning },
            { "clip-ended": clipEnded },
            { "clip-deleted": clipDeleted },
            { "track-is-new": !!trackIsNew },
            { "name-fade": slider === "100%" },
        )

        cliptimeArr = cliptime.split(":")
        cliptime = `${cliptimeArr[1]}:${cliptimeArr[2]}`
        const isAudio = (mimetype.indexOf("mp3") !== -1 || mimetype.indexOf("audio") !== -1)

        return (
            <section className={className}>

                <header className="Track__header">
                    <span className="rewind" onClick={this.rewind}></span>

                    <span className={cl("slider-1", { "d-none": slider === 0 })} style={{ right: slider }}></span>
                    <span className={cl("slider-2", { "d-none": slider === 0 })} style={{ right: slider }}></span>

                    <div className="Tracks__grid">
                        <div className="id">{id}</div>
                        <div className="play">
                            <span onClick={this.play}>
                                {(isAudio)
                                    ? <SvgPlay className="icon-play" variant="prim" size="xs1" title="Воспроизвести" />
                                    : <SvgPlayVideo className="icon-play" variant="prim" size="xs1" title="Воспроизвести" />
                                }
                            </span>
                            <span onClick={this.pause}>
                                <SvgPause className="icon-pause" variant="prim" size="xs1" title="Приостановить" />
                            </span>
                        </div>
                        <div className="name" data-track-id={id}>
                            <h4 data-track-id={id} className="desktop selectable-item__color" style={{ color }}>{name.length > 40 ? `${name.slice(0, 38)}…` : name}</h4>
                            {trackWarning
                                ? <h4 data-track-id={id} className="mobile selectable-item__color" style={{ color }}>{name.length > 23 ? `${name.slice(0, 23)}…` : name}</h4>
                                : <h4 data-track-id={id} className="mobile selectable-item__color" style={{ color }}>{name.length > 28 ? `${name.slice(0, 28)}…` : name}</h4>}
                        </div>
                        <div className="ctime" title={ctime_t}>
                            {ctime_d}
                        </div>
                        <div className="time">
                            <span className="from">{currentTime}</span>
                            <span className="to">{cliptime.slice(0, 5)}</span>
                        </div>
                        <div className="pri">
                            {pri && <span className='priText' title='Приоритет трансляции'><b>{pri}</b></span>}
                        </div>
                        <div className="warning">
                            {clipEnded
                                ? <SvgWarning variant="sec" size="sm1" title="Завершено" />
                                : <SvgWarning variant="prim" size="sm1" title="Удалено" />
                            }
                        </div>

                        {/* <div className="open">
                            <span onClick={this.toggleOpen}>
                                <SvgArrowDownLight variant="dark" size="mr" title={opened ? "Свернуть" : "Развернуть"}/>
                            </span>
                        </div> */}
                    </div>
                </header>

                {/* {(this.state.listLoaded || this.state.opened) && <List/>} */}
            </section>
        )
    }
}

Track.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(["gray"]),
    track: PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
        src: PropTypes.string,
    }),
    trackWarning: PropTypes.bool,
    clipEnded: PropTypes.bool,
    clipDeleted: PropTypes.bool,
    disabled: PropTypes.bool,
    trackPri: PropTypes.string,
}

const stateToProps = state => ({
    selected: state.tracks.selected,
    markItems: [...state.marks[state.marks.current].items],
})

const actionsToProps = {
    openModal
}

export default connect(stateToProps, actionsToProps)(Track)