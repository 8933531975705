import React, { useEffect, useState, useMemo } from 'react'

import { cl } from '../../../../../../utilities/cl'
import { generateStateKey } from '../../../../../../utilities/generateStateKey'
import { getNextSelected } from '../../../../../../utilities/getNextSelected'
import { isFunc } from '../../../../../../utilities/isFunc'
import { mediaTabletAndUp } from '../../../../../../utilities/media'

import SvgPlayVideo from '../../../../../SVG/PlayVideo'
import SvgPlay from '../../../../../SVG/Play'
import SvgPause from '../../../../../SVG/Pause'
import SvgUpdate from '../../../../../SVG/Update'
import SvgDownload from '../../../../../SVG/Download'
import SvgTrash from '../../../../../SVG/Trash'
import SvgPlus from '../../../../../SVG/Plus'
import SvgMinus from '../../../../../SVG/Minus'
import SvgArrowUpDown from '../../../../../SVG/ArrowUpDown'
import SvgOk from '../../../../../SVG/Ok'

import List from '../../../../../List'
import StringDays from '../../../../../StringDays'
import Button from '../../../../../Button'
import ButtonCircle from '../../../../../ButtonCircle'

import modalChangeTrack from './modals/changeTrack'
import modalEditItem from './modals/editItem'
import { download } from '../../../../../../utilities/download'


let changedTracksForItemsKey = ""
let changedDaymasksKey = ""

const MediaList = ({
    stateKey,
    className,
    type,
    store,
    activeTabRef,

    tracks,
    items,
    selected = [],
    selectedBlockNumber,
    loading,
    saving,
    deleting,

    toggleSelectedItems,
    saveItemDaymask,
    deleteTrackFromPosition,
    deleteItems,
    openModal,
    closeModal,
    selectBlock
}) => {

    const [playingId, setPlayingId] = useState(-1)
    const [lastPlaying, setLastPlaying] = useState(-1)
    const [audio, setAudio] = useState(new Audio())

    const [sortBy, setSortBy] = useState(null)
    const [sortOrder, setSortOrder] = useState(null)
    const sortTypes = {
        "__blocknumber": "number",
        "starttime": "time",
    }

    function hClick({ id, items }, event) {
        const target = event.target
        const targetPlay = target.closest(".play")
        const targetPause = target.closest(".pause")

        // Управление музыкой
        if (targetPlay || targetPause) {
            const trackHTML = target.closest(".track")
            const trackId = Number(trackHTML.dataset.trackId)

            if (trackId && !isNaN(trackId)) {

                // Воспроизведение
                if (targetPlay) {
                    const track = tracks[trackId] || {}
                    const isAudio = (track.mimetype.indexOf("mp3") !== -1 || track.mimetype.indexOf("audio") !== -1)
                    const isVideo = (track.mimetype.indexOf("mp4") !== -1 || track.mimetype.indexOf("video") !== -1)

                    // Аудио
                    if (isAudio) {
                        play({
                            trackId,
                            filename: track.filename,
                        })

                        // Видео
                    } else if (isVideo) {
                        pause()
                        openModal({
                            type: "video",
                            data: {
                                video: {
                                    title: track.name,
                                    mp4: track.filename,
                                }
                            }
                        })
                    }
                }

                // Пауза
                else if (targetPause) {
                    pause()
                }
            }

            // Выделение
        } else {
            if (isFunc(toggleSelectedItems)) {
                selected = getNextSelected({
                    multiple: true,
                    itemId: id,
                    selected,
                    ctrlKey: event.ctrlKey || event.metaKey,
                    shiftKey: event.shiftKey,
                    items,
                })

                toggleSelectedItems({
                    type,
                    stateKey,
                    itemIds: selected,
                })
            }
        }
    }

    const hModalEditItem = ({ id }) => {
        const itemStateKey = generateStateKey([stateKey, id])

        modalEditItem({
            stateKey,
            itemId: id,
            side: Number(activeTabRef.current.dataset.activeTab),
            daymask: store[itemStateKey]["daymask"],
            withoutAds: store[itemStateKey]["__without-ads"],
            openModal,
        })
    }

    const hDeleteItem = ({ id }) => {
        openModal({
            type: "confirm",
            size: "sm",
            data: {
                title: `Вы точно хотите удалить расписание №${id}?`,
                buttons: {
                    cancel: {
                        title: "Вернуться",
                        onClick: () => {
                            closeModal("confirm")
                        }
                    },
                    confirm: {
                        title: "Удалить",
                        onClick: () => {
                            deleteItems([id])
                            closeModal("confirm")
                        }
                    }
                }
            }
        })
    }

    function play({ trackId, filename }) {
        audio.pause()

        // Если тот же
        if (lastPlaying === trackId) {
            audio.play()
            setPlayingId(trackId)

            // Если другой
        } else {
            setPlayingId(trackId)
            setLastPlaying(trackId)
            setAudio(new Audio(filename))
        }
    }

    function pause() {
        audio.pause()
        setPlayingId(-1)
    }

    useEffect(() => {
        audio.addEventListener("canplaythrough", () => {
            audio.play()
        })
    }, [audio])

    //
    // render
    //

    className = cl(
        className,
        "ObjectScheduleGroupSmartMediaList"
    )

    let memoItems = useMemo(() => getItems(), [items, tracks])

    useEffect(() => {
        let sounds = []

        items.map(itemStateKey => {
            const item = store[itemStateKey]

            if (selected.indexOf(item.id) === -1) return


            const insideStart = tracks[item.insideStart] || undefined
            const insideStop = tracks[item.insideStop] || undefined
            const outsideStart = tracks[item.insideStart] || undefined
            const outsideStop = tracks[item.outsideStop] || undefined

            // if (!isNaN(insideStart))
            sounds.push(insideStart)

            // if (!isNaN(insideStop))
            sounds.push(insideStop)

            // if (!isNaN(outsideStart))
            sounds.push(outsideStart)

            // if (!isNaN(outsideStop))
            sounds.push(outsideStop)


        })

        // console.log({ selected, sounds });
    }, [selected, items])


    const downloadSounds = () => {

        let sounds = []

        items.map(itemStateKey => {
            const item = store[itemStateKey]

            if (selected.indexOf(item.id) === -1) return


            const insideStart = tracks[item.insideStart] || undefined
            const insideStop = tracks[item.insideStop] || undefined
            const outsideStart = tracks[item.insideStart] || undefined
            const outsideStop = tracks[item.outsideStop] || undefined

            if (!isNaN(insideStart?.id))
                sounds.push(insideStart)

            if (!isNaN(insideStop?.id))
                sounds.push(insideStop)

            if (!isNaN(outsideStart?.id))
                sounds.push(outsideStart)

            if (!isNaN(outsideStop?.id))
                sounds.push(outsideStop)


        })

        sounds = [... new Set(sounds)]

        let tracksLinks = []

        sounds.map(track => {
            tracksLinks.push(`https://a.ms-box.ru/clip/download.php?id=${track.id}&token=${window.localStorage.getItem("sessionToken")}`)
        })
        tracksLinks.forEach((link, index) => {
            setTimeout(() => download(link), index * 500)
        })
        // download(tracksLinks)
    }

    return (
        <div className={className}>
            <List
                type="simple"
                scroll={{ on: true, }}
                header={{
                    size: "sm",
                    element: getHeader(),
                }}
                content={{
                    items: memoItems
                    // items: getItems()
                }}
                sort={{
                    by: sortBy,
                    order: sortOrder,
                    type: sortTypes[sortBy],
                    deps: [playingId, changedTracksForItemsKey, changedDaymasksKey],
                }}
                selected={selected}
                onClick={hClick}
                loading={loading}
                buttons={{
                    edit: {
                        on: true,
                        mobile: true,
                        onClick: hModalEditItem,
                    },
                    remove: {
                        on: true,
                        mobile: true,
                        onClick: hDeleteItem,
                    }
                }}
            />
            {getSelectAll("v-simple")}
        </div>
    )

    function getHeader() {
        return (
            <div className="ObjectScheduleGroupSmartMediaList__header ObjectScheduleGroupSmartMediaList__grid">
                <div className="id">
                    <Button type="string" onClick={() => toggleSort("__blocknumber")}>
                        <SvgArrowUpDown title="Сортировка по номеру" />
                        <span className="v-extend v-mobile-hidden">
                            Блок
                        </span> <span className="v-mobile-hidden">№</span>
                        <span className="v-mobile">
                            Порядок
                        </span>
                    </Button>
                </div>

                <div className="time">
                    <Button type="string" onClick={() => toggleSort("starttime")}>
                        <SvgArrowUpDown title="Сортировка по времени начала" />
                        <span className="v-simple v-mobile-hidden">
                            Нач.
                        </span>
                        <span className="v-extend v-mobile-hidden">
                            Начало
                        </span>
                        <span className="v-mobile">
                            Время
                        </span>
                    </Button>
                </div>

                {mediaTabletAndUp(
                    <>
                        <div className="advert v-extend">
                            Без рекламы
                        </div>

                        <div className="double">
                            {getPlus("Начало", "start")}
                            {getPlus("Окончание", "stop")}
                        </div>

                        <div className="far buttons">
                            <Button
                                type="string"
                                onClick={() => downloadSounds()}
                                disabled={!!!selected.length}
                            >
                                <SvgDownload size="sm1" variant="dark" title='Скачать' />
                            </Button>
                            <Button
                                type="string"
                                onClick={() => saveItemDaymask(selected)}
                                disabled={saving || !(selected.length)}
                            >
                                <SvgUpdate size="sm1" variant="dark" />
                            </Button>
                            <Button
                                type="string"
                                onClick={deleteItems}
                                disabled={deleting || selected.length === 0}
                            >
                                <SvgTrash size="sm1" variant="dark" />
                            </Button>
                            {getSelectAll("v-extend")}
                        </div>
                    </>,
                    null
                )}
            </div>
        )
    }


    function getItems() {
        let nextItems = [],
            className

        changedTracksForItemsKey = ""
        changedDaymasksKey = ""

        if (!Array.isArray(items)) {
            return []
        }

        items.map(itemStateKey => {
            const item = store[itemStateKey]

            className = cl(
                "ObjectScheduleGroupSmartMediaList__item",
                "ObjectScheduleGroupSmartMediaList__grid",
                { "without-ads": (item["__without-ads"] === true) },
            )

            changedDaymasksKey += item.daymask

            nextItems.push({
                id: item.id,
                __blocknumber: item.__blocknumber,
                starttime: item.starttime,
                element: <>
                    <div
                        className={className}
                        data-item-id={item.id}
                        data-item-advert={item["__without-ads"]}
                        key={item.id}
                    >
                        <div className="id">
                            {item.__blocknumber}
                        </div>

                        <div className="time">
                            {item.__starttime}
                        </div>

                        <div className="advert v-extend v-mobile-hidden"></div>

                        <div className="double tracks tab-1">
                            {getTrack({ trackId: item.insideStart })}
                            {getTrack({ trackId: item.insideStop })}
                        </div>
                        <div className="double tracks tab-0">
                            {getTrack({ trackId: item.outsideStart })}
                            {getTrack({ trackId: item.outsideStop })}
                        </div>

                        <div className="far days">
                            <StringDays daymask={item.daymask} />
                        </div>
                    </div>
                </>
            })
        })

        return nextItems
    }

    function getTrack({ trackId }) {
        const track = tracks[trackId] || {}

        changedTracksForItemsKey += trackId

        return (
            <div className={`double__item track ${trackId === playingId ? "playing" : ""} ${isNaN(trackId) ? "disabled" : ""}`} data-track-id={trackId}>
                <div className="left track-buttons">
                    <SvgPause className="pause icon" size="sm1" variant="prim" />
                    {(track.origext === "mp4")
                        ? <SvgPlayVideo className="play icon" size="sm1" variant="prim" />
                        : <SvgPlay className="play icon" size="sm1" variant="prim" />
                    }
                </div>
                <div className="right track-title List__text-fade">
                    {track.name}
                </div>
            </div>
        )
    }

    function getPlus(title, position) {
        const hChangeTrack = () => modalChangeTrack({
            stateKey,
            side: Number(activeTabRef.current.dataset.activeTab),
            position,
            openModal,
        })

        const hDeleteTrackFromPosition = () => deleteTrackFromPosition({
            stateKey,
            side: Number(activeTabRef.current.dataset.activeTab),
            position,
        })

        return (
            <div className="double__item plus">
                <div className="left"><div className="icon"></div></div>
                <div className="right plus-buttons">

                    <div className="plus-button">
                        <div className="title">
                            <span className="v-extend">
                                Объявление:
                            </span>
                            {title}
                        </div>
                        <div className="button">
                            <ButtonCircle size="sm" onClick={hChangeTrack} disabled={!(selected.length)}>
                                <SvgPlus />
                            </ButtonCircle>
                            <ButtonCircle size="sm" onClick={hDeleteTrackFromPosition} disabled={saving || !(selected.length)}>
                                <SvgMinus />
                            </ButtonCircle>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    function getSelectAll(className) {
        const hSelectBlock = () => selectBlock({
            stateKey,
            blockNumber: selectedBlockNumber
        })

        return (
            <Button
                className={`select-all ${className}`}
                type="string"
                onClick={hSelectBlock}
                disabled={!(selected.length === 1)}
            >
                <SvgOk size="xs1" variant="dark-lg" />
                Выделить весь блок
            </Button>
        )
    }

    function toggleSort(by) {
        let order

        if (sortBy === by) {
            order = sortOrder === "ASC" ? "DESC" : "ASC"
        } else {
            order = "ASC"
        }

        setSortOrder(order)
        setSortBy(by)
    }
}

export default MediaList