export const UPDATE = "Modal/VideoCreate/UPDATE"
export const EDIT = "Modal/VideoCreate/EDIT"
export const LOADING = "Modal/VideoCreate/LOADING"
export const CREATE_VIDEO = "Modal/VideoCreate/CREATE_VIDEO"


export const update = () => ({
    type: UPDATE
})



export const editVideo = payload => ({
    type: EDIT,
    payload
})


export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const createVideo = videoData => ({
    type: CREATE_VIDEO,
    payload: videoData
})
