import { DO_LOGOUT } from '../Login/actions'
import { SHOW_ALERT_ERROR, HIDE_ALERT_ERROR, SHOW_ALERT_SUCCESS, HIDE_ALERT_SUCCESS, SHOW_ALERT_INFO, HIDE_ALERT_INFO } from './actions'

const initialState = {
    error: {
        show: false,
        text: "",
        errors: [],
        ms: 8000
    },
    success: {
        show: false,
        text: "",
        responses: [],
        ms: 8000
    },
    info: {
        show: false,
        text: "",
        responses: [],
        ms: 8000
    },
    warning: {
        show: false,
        text: "",
        responses: [],
        ms: 8000
    }
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case SHOW_ALERT_ERROR:
            return {
                ...state,
                error: {
                    show: true,
                    text: payload.text,
                    errors: payload.errors || [],
                    ms: payload.ms || 8000
                }
            }
        case HIDE_ALERT_ERROR:
            return {
                ...state,
                error: {
                    ...initialState.info
                }
            }

        case SHOW_ALERT_INFO:
            return {
                ...state,
                info: {
                    show: true,
                    text: payload.text,
                    ms: payload.ms || 8000
                }
            }
        case HIDE_ALERT_INFO:
            return {
                ...state,
                info: {
                    ...initialState.error
                }
            }

        case SHOW_ALERT_SUCCESS:
            return {
                ...state,
                success: {
                    show: true,
                    text: payload.text,
                    responses: payload.responses || [],
                    ms: payload.ms || 8000,
                    warning: payload.warning || null
                }
            }
        case HIDE_ALERT_SUCCESS:
            return {
                ...state,
                success: {
                    ...initialState.success
                }
            }
            case DO_LOGOUT:
                return initialState;
    }
    return state
}

export default reducer