import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { isFunc } from '../../../../utilities/isFunc'
import { cl } from '../../../../utilities/cl'

import Spinner from '../../../Spinner'
import Select from '../../../Form/Select'
import ButtonCircle from '../../../ButtonCircle'
import SvgPlus from '../../../SVG/Plus'
import SvgEdit from '../../../SVG/Edit'
import SvgTrash from '../../../SVG/Trash'
import SvgCopy from '../../../SVG/Copy'

import Item from '../Group/Item'

import Add from './Add'
import Edit from './Edit'
import axios from 'axios'


const Periods = ({
    type,
    className = "",
    name = "settings-periods",
    showCopied = true,
    selected,
    onChange,

    periods = [],
    selectedLength = 0,
    loading,

    loadPeriods,
    copyPeriod,
    deletePeriod,
    openModal,
    closeModal,

}) => {
    const sltd = selected;
    // const [sltd, setSelected] = useState(selected)
    const [sorted_periods, setSortedPeriods] = useState([])

    useEffect(() => {
        loadPeriods({ type })
    }, [])

    const lengthDisable = selectedLength > 1

    useEffect(() => {
        console.log(selected)
    }, [selected])

    useEffect(() => {
        const p = [...periods].sort((a, b) => (moment(b.fromdate, "DD.MM.YYYY").format('YYYYMMDD') - moment(a.fromdate, "DD.MM.YYYY").format('YYYYMMDD')))
        setSortedPeriods(p)
    }, [periods])

    const hChange = ({ value }) => {
        if (sltd !== value) {
            //setSelected(value)
            if (isFunc(onChange)) {
                onChange({
                    name,
                    value
                })
            }
        }
    }

    className = cl(
        className,
        "SettingsPeriods"
    )

    return (
        <Item title="Периоды трансляции">
            <div className={className}>

                {loading
                    ? <Spinner />
                    : <>
                        <div className="SettingsPeriods__select">
                            <Select
                                type="options"
                                options={sorted_periods}
                                placeholder="Выбрать период"
                                selected={sltd}
                                onChange={hChange}
                            />
                        </div>

                        <div className="SettingsPeriods__buttons spp-btns">
                            <ButtonCircle
                                size="sm"
                                onClick={modalAdd}
                            >
                                <SvgPlus />
                            </ButtonCircle>

                            <ButtonCircle
                                size="sm"
                                onClick={modalEdit}
                                disabled={periods.length <= 1 || sltd === -1 || !sltd}
                            >
                                <SvgEdit />
                            </ButtonCircle>
                            <ButtonCircle
                                size="sm"
                                onClick={() => openModal({
                                    type: "confirm",
                                    size: "sm",
                                    data: {
                                        title: "Вы точно хотите удалить выделенный период?",
                                        buttons: {
                                            cancel: {
                                                title: "Вернуться",
                                                onClick: () => {
                                                    closeModal("confirm")
                                                }
                                            },
                                            confirm: {
                                                title: "Удалить",
                                                onClick: () => {
                                                    deletePeriod({ type, id: sltd })
                                                    closeModal("confirm")
                                                }
                                            }
                                        }
                                    }
                                })}
                                disabled={periods.length <= 1 || sltd === -1 || !sltd}
                            >
                                <SvgTrash />
                            </ButtonCircle>


                            {showCopied &&
                                <ButtonCircle
                                    size="sm"
                                    onClick={async () => {
                                        copyPeriod({ type, id: sltd })
                                    }}
                                    disabled={periods.length <= 1 || sltd === -1 || !sltd || selected.length || lengthDisable}
                                >
                                    <SvgCopy title='Скопировать основной эфир' />
                                </ButtonCircle>
                            }
                        </div>
                    </>
                }

            </div>
        </Item>
    )

    function modalAdd() {
        openModal({
            type: "element",
            size: "sm",
            data: {
                title: "Добавление периода",
                element: <Add type={type} />,
            }
        })
    }

    function modalEdit() {
        openModal({
            type: "element",
            size: "sm",
            data: {
                title: "Замена периода",
                className: "SettingsPeriodModal",
                element: <Edit type={type} id={sltd} />,
            }
        })
    }

}

export default Periods