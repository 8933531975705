import {takeEvery, call, put, select} from 'redux-saga/effects'
import {loadTracks} from '../../store/Tracks/actions'
import {showAlertError} from '../../store/Alert/actions'
import axios from 'axios'
import {CLEAR_ADVERT} from "../../store/AdvertClear/actions";
import {loadIndentObjects} from "../../store/Objects/actions";
import {loadClips} from "../../store/Settings/actions/components/Clips";

export default function* () {
    yield takeEvery(CLEAR_ADVERT, worker)
}

function* worker({payload}) {
    // yield put(loading(true))
    try {
        const selectedTracks = yield select(state => state.tracks.selected)
        const response = yield call(fetchClear, payload)

        if (response.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CLEAR_ADVERT}.`, response],
                text: "Реклама не удалена. Попробуйте повторить."
            }))

        } else {
            yield put(loadTracks())
            yield put(loadTracks({theme: true}))
            yield put(loadIndentObjects())
            yield put(loadClips({
                indentId: selectedTracks[0],
                objectIds: payload,
                required: true
            }))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CLEAR_ADVERT}.`, e],
            text: "Реклама не удалена. Попробуйте повторить."
        }))
    } finally {
        // yield put(loading(false))
    }
}

function fetchClear(ids) {
    let formData = new FormData()
    formData.set('ms_id', ids);
    return axios(
        {
            url: "/timeline/clip/delete",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}