import React from 'react'


const Paperclip = ({ title = '', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <title>{title}</title>
                <path d="M10 2C9.44772 2 9 2.44772 9 3V12H7V3C7 1.34315 8.34315 0 10 0C11.6569 0 13 1.34315 13 3V11C13 13.7614 10.7614 16 8 16C5.23858 16 3 13.7614 3 11V3.5H5V11C5 12.6569 6.34315 14 8 14C9.65685 14 11 12.6569 11 11V3C11 2.44772 10.5523 2 10 2Z" fill="#000000"/>
            </svg>
        </i >
    )
}

export default Paperclip