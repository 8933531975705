export const MODE_ACTIVE_ON = "Search/MODE_ACTIVE_ON"
export const MODE_ACTIVE_OFF = "Search/MODE_ACTIVE_OFF"
export const CHANGE_VALUE = "Search/CHANGE_VALUE"
export const CLEAR_VALUE = "Search/CLEAR_VALUE"
export const PUT_FOUNDED = "Search/PUT_FOUNDED"
export const RELOAD_VALUE = "Search/RELOAD_VALUE"


export const modeActiveOn = type => ({
    type: MODE_ACTIVE_ON,
    payload: {
        type,
        mode: true,
    }
})

export const modeActiveOff = type => ({
    type: MODE_ACTIVE_OFF,
    payload: {
        type,
        mode: false,
    }
})

export const putFounded = ({ type, founded, updated }) => ({
    type: PUT_FOUNDED,
    payload: {
        type,
        founded,
        updated
    }
})

export const changeValue = ({ type, value }) => ({
    type: CHANGE_VALUE,
    payload: {
        type,
        value,
    },
})

export const clearValue = type => ({
    type: CLEAR_VALUE,
    payload: {
        type
    },
})


export const reloadValue = type => ({
    type: RELOAD_VALUE,
    payload: {
        type
    },
})
