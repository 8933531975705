import { combineReducers } from 'redux'
import AlertReducer from './Alert/reducer'
import AppReducer from './App/reducer'
import LoginReducer from './Login/reducer'
import MarksReducer from './Marks/reducer'
import ModalReducer from './Modal/reducer'
import ModalAdsCreateReducer from './Modal/types/AdsCreate/reducer'
import ModalVideoCreateReducer from './Modal/types/VideoCreate/reducer'
import ModalAdsEditReducer from './Modal/types/AdsEdit/reducer'
import ModalAdsReportReducer from './Modal/types/AdsReport/reducer'
import ModalCastinfoReducer from './Modal/types/Castinfo/reducer'
import ModalMediaplanReducer from './Modal/types/Mediaplan/reducer'
import ModalVideoHistoryReducer from './Modal/types/VideoHistory/reducer'
import ModalAdvertCreateReducer from './Modal/types/AdvertCreate/reducer'
import ModalPlaylistCreateReducer from './Modal/types/PlaylistCreate/reducer'
import ModalVideolistCreateReducer from './Modal/types/VideolistCreate/reducer'
import ModalPublicSongsFilter from './Modal/types/PublicSongsFilter/reducer'
import ObjectsReducer from './Objects/reducer'
import ObjectsByTagsReducer from './ObjectsByTags/reducer'
import ObjectsGroupReducer from './ObjectsGroup/reducer'
import OpenedCollapsable from './OpenedCollapsable/reducer'
import PanelReducer from './Panel/reducer'
import CollapsibleReducer from './Collapsible/reducer'
import SearchReducer from './Search/reducer'
import SelectTZReducer from './SelectTZ/reducer'
import SettingsReducer from './Settings/reducer'
import TracksReducer from './Tracks/reducer'
import ContentPlaylistsReducer from './ContentPlaylists/reducer'
import ContentPublicPlaylistsReducer from './PublicPlaylists/reducer'
import ContentVideolistsReducer from './ContentVideolists/reducer'
import ContentVideolistsAllReducer from './ContentAllVideolists/reducer'
import HelpReducer from './Help/reducer'
import ObjectsState from "./Reports/reducer"
import AdvertReport from "./Reports/Advert/reducer"
import MusicReport from "./Reports/Music/reducer"
import SystemReport from "./Reports/System/reducer"

import ContentSortPlaylists from './Content/reducer'

import SettingsTemplates from './Templates/reducer'
import SettingsNotifications from './Notifications/reducer'

import SettingsRoles from './Roles/reducer'

import AdvertPageRules from './AdvertPage/reducer'

import Features from './Features/reducer'


export default combineReducers({
    alert: AlertReducer,
    app: AppReducer,
    marks: MarksReducer,
    modal: ModalReducer,
    modalAdsCreate: ModalAdsCreateReducer,
    modalAdsEdit: ModalAdsEditReducer,
    modalAdsReport: ModalAdsReportReducer,
    modalCastinfo: ModalCastinfoReducer,
    modalMediaplan: ModalMediaplanReducer,
    modalVideoHistory: ModalVideoHistoryReducer,
    modalAdvertCreate: ModalAdvertCreateReducer,
    modalPlaylistCreate: ModalPlaylistCreateReducer,
    modalVideolistCreate: ModalVideolistCreateReducer,
    modalVideoCreate: ModalVideoCreateReducer,
    modalPublicSongsFilter: ModalPublicSongsFilter,
    objects: ObjectsReducer,
    objectsByTags: ObjectsByTagsReducer,
    objectsGroup: ObjectsGroupReducer,
    openedCollapsable: OpenedCollapsable,
    panel: PanelReducer,
    collapsible: CollapsibleReducer,
    search: SearchReducer,
    selectTz: SelectTZReducer,
    settings: SettingsReducer,
    tracks: TracksReducer,
    playlists: ContentPlaylistsReducer,
    public_playlists: ContentPublicPlaylistsReducer,
    videolists: ContentVideolistsReducer,
    videolists_all: ContentVideolistsAllReducer,
    sort_playlists: ContentSortPlaylists,
    login: LoginReducer,
    report: ObjectsState,
    advert_report: AdvertReport,
    music_report: MusicReport,
    system_report: SystemReport,
    settings_templates: SettingsTemplates,
    help: HelpReducer,
    settings_notifications: SettingsNotifications,
    settings_roles: SettingsRoles,
    advert_page: AdvertPageRules,
    features: Features
})