import { DO_LOGOUT } from '../Login/actions'
import { CHANGE_COLLAPSIBLE } from './actions'

const inititalState = {
    collapsibleName: "music",
}

const reducer = (state = inititalState, action) => {
    switch (action.type) {
        case CHANGE_COLLAPSIBLE:
            let next = action.payload !== state.collapsibleName ? action.payload : state.collapsibleName
            return {
                ...state,
                collapsibleName: next
            }
            case DO_LOGOUT:
                return inititalState;
    }
    return state
}

export default reducer