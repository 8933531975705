import { DO_LOGOUT } from '../Login/actions'
import {
    LOAD_TEMPLATES, LOADING,
    PUT_TEMPLATES,
    UPDATE_TEMPLATES
} from './actions'

const inititalState = {
    loading: false,
    templates: []
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case LOAD_TEMPLATES:
            return {
                ...state,
            }
        case PUT_TEMPLATES:
            return {
                ...state,
                templates: payload.templates[0],
            }
        case UPDATE_TEMPLATES:
            return {
                ...state,
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }
            case DO_LOGOUT:
                return inititalState;    
    }
    return state
}

export default reducer