import {takeEvery, call, put} from 'redux-saga/effects'
import {buildGETUrl} from '../../utilities/buildGETUrl'
import {LOGIN, doLogin, Login, DO_LOGIN, loading, DO_LOGOUT, doLogoutAction, DO_LOGOUT_ACTION, doLogout} from '../../store/Login/actions'
import {showAlertError} from '../../store/Alert/actions'
import axios from 'axios'
import {didMount} from "../../store/App/actions";

export default function* () {
    yield takeEvery(DO_LOGOUT_ACTION, worker)
}

function* worker({payload}) {
    // console.log({payload});
    // return
    
    try {
        yield put(doLogout());
        localStorage.sessionToken = "";
        localStorage.removeItem('master')
        yield put(loading(true))
        const auth = yield call(fetchAuth, payload)
        yield put(loading(false))


        payload.navigateToLogin()
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DO_LOGOUT}.`, e],
            text: "Выход не пройден. Попробуйте заново."
        }))
    }
}

function fetchAuth(data) {
    return axios.post(`/account/logout`)
        .then(response => response.data)
}