import { DO_LOGOUT } from '../Login/actions'
import {ADD_MARK, UPDATE_MARK, DELETE_MARK, PUT_CURRENT_MARK} from './actions'

const initialState = {
    0: {
        items: []
    },
    current: 0
}

const reducer = (state = initialState, { type, payload }) => {
    switch(type) {

        case ADD_MARK:
            return {
                ...state,
                current: payload,
                [payload]: {
                    items: []
                }
            }

        case UPDATE_MARK:
            return {
                ...state,
                [payload.markId]: {
                    items: payload.items
                }
            }

        case DELETE_MARK:
            delete state[payload]
            return {
                ...state,
                current: 0,
            }
        
        case PUT_CURRENT_MARK:
            return {
                ...state,
                current: payload,
            }

            case DO_LOGOUT:
                return initialState;
    }
    return state
}

export default reducer