import { takeEvery, all, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { DELETE_TRACKS, loadTracks } from '../../store/Tracks/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { reloadValue } from '../../store/Search/actions'

export default function* () {
    yield takeEvery(DELETE_TRACKS, worker)
}

function* worker(action) {
    if (action.payload && action.payload[0]) {
        const tracksIds = action.payload

        try {
            let deletedSuccess = true
            const deleted = yield all(
                tracksIds.map(trackId => call(fetchDeleteTrack, trackId))
            )

            deleted.map(d => { if (d.error) deletedSuccess = false })

            if (deletedSuccess) {
                
                yield put(loadTracks({ callback: reloadValue }))


            } else {
                yield put(showAlertError({
                    errors: [`Ошибка сети при ${DELETE_TRACKS}.`, deleted],
                    text: "Не все ролики были удалены. Попробуйте заново."
                }))
            }

        }
        catch (e) {
            yield put(showAlertError({
                errors: [`Ошибка сети при ${DELETE_TRACKS}.`, e],
                text: "Не все ролики были удалены. Попробуйте заново."
            }))
        }
    }
}

function fetchDeleteTrack(trackId) {
    return axios.get("/clip/delete", { params: { id: trackId } })
        .then(response => response.data)
}