export const DELETE_VIDEOLISTS = "Videolists/DELETE_VIDEOLISTS"
export const LOAD_VIDEOLISTS = "Videolists/LOAD_VIDEOLISTS"
export const LOAD_VIDEOLIST = "Videolists/LOAD_VIDEOLIST"
export const UPDATE_VIDEOLIST = "Videolists/UPDATE_VIDEOLIST"
export const LOADING = "Videolists/LOADING"
export const PUT_VIDEOLISTS = "Videolists/PUT_VIDEOLISTS"
export const SET_CURRENT_VIDEOLIST = "Videolists/SET_CURRENT_VIDEOLIST"
export const TOGGLE_SELECTED_VIDEOLIST = "Videolists/TOGGLE_SELECTED_VIDEOLIST"
export const TOGGLE_SELECTED_VIDEO = "Videolists/TOGGLE_SELECTED_VIDEO"
export const ADD_VIDEO_TO_LIST = "Videolists/ADD_VIDEO_TO_LIST"
export const DOWNLOAD_VIDEOS = "Videolists/DOWNLOAD_VIDEOS"
export const SORT_VIDEOLISTS = "Videolists/SORT_VIDEOLISTS"
export const SORT_VIDEOS = "Videolists/SORT_VIDEOS"

export const loadVideolists = payload => ({
    type: LOAD_VIDEOLISTS,
    payload
})

export const loadVideolist = (id) => ({
    type: LOAD_VIDEOLIST,
    payload: id
})

export const updateVideolist = videolist => ({
    type: UPDATE_VIDEOLIST,
    payload: videolist
})

export const setCurrentVideolist = videolist => ({
    type: SET_CURRENT_VIDEOLIST,
    payload: videolist
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const deleteVideolists = selectedVideolists => ({
    type: DELETE_VIDEOLISTS,
    payload: selectedVideolists
})
export const putVideolists = videolists => ({
    type: PUT_VIDEOLISTS,
    payload: videolists
})

export const toggleSelectedVideolist = ({ id, selected, simple, required }) => ({
    type: TOGGLE_SELECTED_VIDEOLIST,
    payload: {
        id,
        selected,
        simple,
        required,
    }
})
export const toggleSelectedVideo = ({ id_video, selected_video, onlyOne, setAll }) => ({
    type: TOGGLE_SELECTED_VIDEO,
    payload: {
        id_video,
        selected_video,
        setAll,
        onlyOne
    }
})
export const addVideoToList = ({ id_videolist, selected_song, songs }) => ({
    type: ADD_VIDEO_TO_LIST,
    payload: {
        id_videolist,
        selected_song,
        songs
    }
})
export const downloadVideos = ({ videosIds }) => ({
    type: DOWNLOAD_VIDEOS,
    payload: {
        videosIds
    }
})
export const sortVideolists = sortSource => ({
    type: SORT_VIDEOLISTS,
    payload: sortSource
})
export const sortVideos = sortSource => ({
    type: SORT_VIDEOS,
    payload: sortSource
})
