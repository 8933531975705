import React from 'react'
import { connect } from 'react-redux'
import { hideAlertInfo } from '../../store/Alert/actions'

import outside from '../../utilities/outside'


const AlertInfo = ({ text, warning, responses, show, ms, hideAlertInfo }) => {
    if (show) {
        setTimeout(() => hideAlertInfo(), ms)

        if (responses && responses[0]) responses.map(m => console.log(m))

        return (
            <>
                {outside(
                    <>
                        <div className="Alert AlertInfo">
                            {text}
                        </div>
                    </>
                )}
            </>
        )
    } else {
        return <></>
    }
}

const stateToProps = state => ({
    show: state.alert.info.show,
    text: state.alert.info.text,
    responses: state.alert.info.responses,
    ms: state.alert.info.ms,
})

const actionsToProps = {
    hideAlertInfo
}

export default connect(stateToProps, actionsToProps)(AlertInfo)