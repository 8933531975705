import { takeEvery, call, put } from 'redux-saga/effects';
import { CREATE_VIDEO } from '../../../store/Modal/types/VideoCreate/actions';
import { closeModal } from '../../../store/Modal/actions';
import { loadVideolists, loadVideolist } from '../../../store/ContentVideolists/actions';
import { showAlertError } from '../../../store/Alert/actions';
import axios from 'axios';
import { loadingText, loading } from "../../../store/App/actions";
import { store } from "../../../connect";

const FORM_DATA_KEYS = ['name', 'description', 'filename']


export default function* () {
    yield takeEvery(CREATE_VIDEO, worker);
}

function* worker({ payload }) {
    console.log({ payload });

    let add_data = {}

    try {

        let videolist_songs = payload.videolist_songs;
        let createdSuccess = true;
        let lastInsertId = 0;
        let selected = [];
        if (typeof payload.list !== 'undefined' && payload.list.length >= 2) {
            const list = payload.list;
            let created = [];
            for (let [index, videoItem] of list.entries()) {
                payload.setVal(index, 'status', 1);
                let payload_to_load = {}
                Object.assign(payload_to_load, payload, videoItem)

                try {
                    yield put(loading(true));
                    yield put(loadingText('Идет загрузка файла'));

                    const task = yield call(fetchCreate, {
                        ...payload_to_load,
                        setVal: payload.setVal,
                        index: index,
                        videolist_id: payload.videolist_id
                    });
                    if (task.error) {
                        payload.setVal(index, 'status', -1);
                        payload.setVal(index, 'error', task.error);
                    } else {
                        payload.setVal(index, 'status', 2);
                        videolist_songs.push(task.success.id)
                    }
                    created.push(task);
                } catch (e) {
                    payload.setVal(index, 'error', e);
                    payload.setVal(index, 'status', -1);
                }
            }
            created.map(d => {
                if (d.error) createdSuccess = false;
            });
        } else {
            yield put(loading(true));
            yield put(loadingText('Идет загрузка файла'));
            let payload_to_load = {}
            Object.assign(payload_to_load, payload)

            let created = yield call(fetchCreate, payload_to_load);
            if (created.error) {
                createdSuccess = false;
            } else {
                selected.push(Number(created.success.lastInsertId));
                lastInsertId = created.success.lastInsertId;
            }
            videolist_songs.push(created.success.id)
        }

        if (!createdSuccess) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_VIDEO}.`, {}],
                text: "Ролик не был создан. Попробуйте повторить."
            }));
            yield put(closeModal("videoCreate"));
        } else {


            add_data = {
                id: payload.current_videolist,
                songs: videolist_songs.join(",")
            }
            const added = yield call(fetchAdd, add_data)
            yield put(closeModal("videoCreate"))
            yield put(loadVideolists({added: payload.current_videolist}))
            yield put(loadVideolist(payload.current_videolist))


            return;
        }


        add_data = {
            id: payload.current_videolist,
            songs: videolist_songs.join(",")
        }
        const added = yield call(fetchAdd, add_data)
        yield put(closeModal("videoCreate"))
        yield put(loadVideolists({added: payload.current_videolist}))
        yield put(loadVideolist(payload.current_videolist))



    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CREATE_VIDEO}.`, e],
            text: "Ролик не был создан. Попробуйте повторить."
        }));
    } finally {
        yield put(loading(false));
        yield put(loadingText(''));
    }
}

function fetchCreate(videoData) {
    let formData = new FormData();
    if (videoData && videoData.name) {
        Object.keys(videoData).forEach(name => {
            if (FORM_DATA_KEYS.includes(name))
                formData.set(name, videoData[name]);
        });
    }

    return axios({
        url: "/videolist/tracks/insert",
        method: "POST",
        data: formData,
        onUploadProgress: (progressEvent) => {
            const val = Math.ceil(progressEvent.loaded / progressEvent.total * 100);
            if (val >= 100) {
                store.dispatch(loadingText(`[${videoData.name}]: Идет конвертация файла, это займет от 15 до 60 секунд, пожалуйста, не обновляйте страницу.`));
            } else {
                store.dispatch(loadingText(`[${videoData.name}]: Загрузка файла: ${val}%`));
            }
        }
    })
        .then(response => response.data);
}


function fetchAdd(addData) {
    let formData = new FormData()
    if (addData && addData.songs) {
        Object.keys(addData).forEach(name => {
            formData.set(name, addData[name])
        })
    }

    return axios(
        {
            url: `/videolist/set?id=${addData.id}`,
            method: "POST",
            data: formData,

        }
    )
        .then(response => response.data)
}