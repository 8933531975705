export const SHOW_ALERT_ERROR = 'Alert/SHOW_ALERT_ERROR'
export const HIDE_ALERT_ERROR = 'Alert/HIDE_ALERT_ERROR'

export const SHOW_ALERT_INFO = 'Alert/SHOW_ALERT_INFO'
export const HIDE_ALERT_INFO = 'Alert/HIDE_ALERT_INFO'

export const SHOW_ALERT_SUCCESS = 'Alert/SHOW_ALERT_SUCCESS'
export const HIDE_ALERT_SUCCESS = 'Alert/HIDE_ALERT_SUCCESS'


export const showAlertError = data => ({
    type: SHOW_ALERT_ERROR,
    payload: {
        text: data.text,
        errors: data.errors,
        ms: data.ms        
    }
})


export const hideAlertError = () => ({
    type: HIDE_ALERT_ERROR
})

export const showAlertInfo = data => ({
    type: SHOW_ALERT_INFO,
    payload: {
        text: data.text,
        ms: data.ms        
    }
})

export const hideAlertInfo = () => ({
    type: HIDE_ALERT_INFO
})


export const showAlertSuccess = data => ({
    type: SHOW_ALERT_SUCCESS,
    payload: {
        text: data.text,
        responses: data.responses,
        ms: data.ms,
        warning: data.warning 
    }
})

export const hideAlertSuccess = () => ({
    type: HIDE_ALERT_SUCCESS
})