import React, { useRef, useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import VideolistsComponent from '../../../../../components/ContentVideo'
import { generateStateKey } from '../../../../../utilities/generateStateKey'
import { cl } from '../../../../../utilities/cl'
import { showAlertSuccess, showAlertError } from '../../../../../store/Alert/actions'
import List from '../../../../../components/DraggableList'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'
import SvgDownload from '../../../../../components/SVG/Download'
import SvgPlay from '../../../../../components/SVG/Play'
import Song from './Songs'
import Empty from './Empty'


import Button from '../../../../../components/Button'
import Ok from '../../../../../components/SVG/Ok'
import Trash from '../../../../../components/SVG/Trash'
import SvgEdit from '../../../../../components/SVG/Edit'

import AllVideos from '../AllVideos'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { downloadVideos } from '../../../../../store/ContentVideolists/actions'
import { componentDisabled } from '../../../../../utilities/componentDisabled'
import SongItem from '../AllVideos/Songs'
import { toggleSelectedVideo, addVideoToList, sortVideos } from '../../../../../store/ContentVideolists/actions'
import { loadVideolistsAllById, toggleSelectedVideoAll } from '../../../../../store/ContentAllVideolists/actions'
import { updateCurrentMark } from '../../../../../store/Marks/actions'
import { MEDIA_BREAKPOINT_LG } from '../../../../../utilities/breakpoints'
import ElementsGroup from '../../../../../components/ElementsGroup'
import ButtonCircle from '../../../../../components/ButtonCircle'
import SvgPlus from '../../../../../components/SVG/Plus'
import { openModal, closeModal } from '../../../../../store/Modal/actions'
import AllVideolists from '../AllVideolists'
import { getNextSelected } from "../../../../../utilities/getNextSelected";
import { isMobile } from "../../../../../utilities/media";

const VideoList = ({
    className = "",
    selectedVideolists,
    currentVideolist,
    currentVideolistAll,
    selected_all_songs,
    songs,
    selected_video,
    selected_all,
    selected_video_all,
    placeNumber,
    active,
    toggleSelected,
    toggleSelectedVideo,
    toggleSelectedVideoAll,
    updateCurrentMark,
    openModal,
    variant,
    selected_public,
    assoc_public_tracks,
    showAlertSuccess,
    showAlertError,
    addVideoToList,
    downloadVideos,
    loadVideolistsAllById,
    sortVideos
}) => {
    const [trackPlaying, setTrackPlaying] = useState(0)
    const [isWatching, setIsWatching] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [audio, setAudio] = useState(new Audio())
    const [lastClickedPlayId, setLastClickedPlayId] = useState(-1)
    const [sort_direction, setSortDirection] = useState("")


    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortVideos({ direction: sort_direction, sort_type: type, id: selectedVideolists[0] })
    }

    useEffect(() => {
        if (lastClickedPlayId === trackPlaying) {
            audio.addEventListener("canplaythrough", audio.play)
        }
    }, [audio])

    const hPlay = ({ trackId, filename }) => {
        if (trackId === trackPlaying) {
            setLastClickedPlayId(trackId)
            audio.play()
            setIsPlaying(true)
            setIsWatching(true)
        } else {
            setLastClickedPlayId(trackId)
            audio.pause()
            setTrackPlaying(trackId)
            setIsPlaying(true)
            setIsWatching(true)
            setAudio(new Audio(filename))
        }
    }

    const hPause = () => {
        setIsPlaying(false)
        setIsWatching(false)
        audio.pause()
    }

    const hStop = () => {
        setIsPlaying(false)
        setIsWatching(false)
    }

    const hRewind = (totime) => {
        if (audio.currentTime > 0) {
            audio.currentTime = totime
        }
    }
    const selectAllS = (e) => {
        let songs_ids = Object.values(currentVideolist?.songs).map(song => {
            return Number(song.id)
        })
        songs_ids.forEach(id => {
            if (selected_video.length === Object.values(currentVideolist?.songs).length) {
                toggleSelectedVideo({
                    id_video: id,
                    onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                })
            } else {
                if (selected_video.indexOf(id) === -1) {
                    toggleSelectedVideo({
                        id_video: id,
                        onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                    })
                }
            }
        })
        updateCurrentMark()
    }

    const toggleSelectedS = ({ }, e) => {
        const songTitle = e.target
        if (songTitle) {
            const songId = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
            if (!isNaN(songId)) {
                const selected = getNextSelected({
                    multiple: true,
                    items: videolist_items,
                    itemId: songId,
                    selected: selected_video,
                    ctrlKey: isMobile() || e.ctrlKey || e.metaKey,
                    shiftKey: e.shiftKey,
                })
                //console.log(selected)
                toggleSelectedVideo({
                    id_video: selected,
                    setAll: true
                })
                //это старое:
                // toggleSelectedVideo({
                //     id_video: songId,
                //     onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                // })
                // updateCurrentMark()
            }
        }
    }

    const selectAllall = (e) => {
        let songs_ids = Object.values(currentVideolistAll.songs).map(song => {
            return Number(song.id)
        })
        songs_ids.forEach(id => {
            if (selected_video_all.length === Object.values(currentVideolistAll.songs).length) {
                toggleSelectedVideoAll({
                    id_video: id,
                    onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                })
            } else {
                if (selected_video_all.indexOf(id) === -1) {
                    toggleSelectedVideoAll({
                        id_video: id,
                        onlyOne: songs_ids.indexOf(id) === -1 ? true : false
                    })
                }
            }
        })
        updateCurrentMark()
    }

    const toggleSelectedAll = ({ }, e) => {
        const songTitle = e.target
        if (songTitle) {
            const songId = Number(songTitle.dataset.songId || songTitle.parentNode.dataset.songId || songTitle.parentNode.parentNode.dataset.songId)
            if (!isNaN(songId)) {
                const selected = getNextSelected({
                    multiple: true,
                    items: videolist_items_all,
                    itemId: songId,
                    selected: selected_video_all,
                    ctrlKey: isMobile() || e.ctrlKey || e.metaKey,
                    shiftKey: e.shiftKey,
                })
                //console.log(selected)
                toggleSelectedVideoAll({
                    id_video: selected,
                    setAll: true
                })
                // toggleSelectedVideoAll({
                //     id_video: songId,
                //     onlyOne: !((e.ctrlKey || e.metaKey || e.shiftKey) || window.outerWidth <= MEDIA_BREAKPOINT_LG)
                // })
                // updateCurrentMark()
            }
        }
    }

    const [videolist_items, setVideolistItems] = useState([])
    const [videolist_items_all, setVideolistItemsAll] = useState([])

    const hSave = () => {
        const collapsible = document.querySelector(".SettingsObjectNetCollapsible")
        const buttonSave = collapsible.querySelector(".SettingsObjectNetSave button")
        if (buttonSave) {
            buttonSave.click()
        }
    }
    className = cl(
        className,
        "Songs_collapsible"
    )
    const getItems = () => {
        return videolist_items.map((song, index) => ({
            id: song.id,
            name: song.name,
            element: < Song
                key={song.id}
                id={song.id}
                name={song.name}
                turn={song.turn}
                cliptime={song.cliptime}
                selected={selected_video}
                tempo={song.tempo}
                ctime={song.ctime}
                songauthor_name={song.songauthor_name}
                player_name={song.player_name}
                album_name={song.album_name}
                genre_name={song.genre_name}
                year={song.year}
                variant={(index % 2 == 0) ? "gray" : ""}
                filename={song.filename}
                audio={audio}
                mimetype={song.mimetype}
                trackPlaying={trackPlaying}
                isPlaying={isPlaying}
                play={hPlay}
                pause={hPause}
                stop={hStop}
                rewind={hRewind}

            />
        }))
    }

    const getAllItems = () => {
        return videolist_items_all.map((song, index) => ({
            id: song.id,
            name: song.name,
            element: < SongItem
                key={song.id}
                id={song.id}
                name={song.name}
                variant={(index % 2 == 0) ? "gray" : ""}
                songs={songs}
                selected={selected_video_all}
                tags={song.tags}
                turn={song.turn}
                cliptime={song.cliptime}
                tempo={song.tempo}
                songauthor_name={song.songauthor_name}
                player_name={song.player_name}
                album_name={song.album_name}
                genre_name={song.genre_name}
                mimetype={song.mimetype}
                year={song.year}
                ctime={song.ctime}
                filename={song.filename}
                audio={audio}
                trackPlaying={trackPlaying}
                isPlaying={isPlaying}
                play={hPlay}
                pause={hPause}
                stop={hStop}
                rewind={hRewind}
            />
        }))
    }
    useEffect(() => {
        if (selected_all.length > 0) {
            loadVideolistsAllById(selected_all[0])
        }
    }, [selected_all[0]])

    useEffect(() => {
        if (currentVideolist?.songs)
            setVideolistItems(Object.values(currentVideolist?.songs))
    }, [currentVideolist])

    useEffect(() => {
        console.log("selected_all_songs", selected_all_songs.length);
        setVideolistItemsAll(selected_all_songs)
    }, [selected_all_songs])

    let videolist_content_all;
    if (videolist_items_all.length > 0) {
        videolist_content_all = <List
            type="simple"
            scroll={{
                on: true,
                data: {
                    variant: "white",
                    size: "lg",
                }
            }}
            onClick={toggleSelectedAll}
            content={{ items: getAllItems(), variant: "none" }}
            droppableId={`3`}
        />
    }

    const handleResult = (result) => {
        const { destination, source } = result
        if (!destination) return
        if (destination.droppableId === `3` && source.droppableId === `3`) return
        if (destination.droppableId === source.droppableId) {
            if (source.droppableId = `2`) {

                let videolist_item = videolist_items[source.index]
                videolist_items.splice(source.index, 1)
                videolist_items.splice(destination.index, 0, videolist_item)
                setVideolistItems([...videolist_items])

            } else if (destination.droppableId === `3`) {
                showAlertError({ text: ` Для изменения видео списка перетащите видео в верхнюю часть экрана.`, ms: 3000 })
                return
            }

        } else {
            if (destination.droppableId !== `3`) {
                let videolist_item_all = videolist_items_all[source.index]
                videolist_items_all.splice(source.index, 1)
                videolist_items.splice(destination.index, 0, videolist_item_all)
                showAlertSuccess({ text: `Видео "${videolist_items[source.index].name}" добавлено в список`, ms: 2000 })
                setVideolistItems([...videolist_items])
                addVideoToList({
                    id_videolist: selectedVideolists[0], selected_song: videolist_items.map(song => {
                        return song.id
                    }), songs: videolist_items
                })
            } else {
                showAlertError({ text: ` Для изменения видео списка перетащите видео в верхнюю часть экрана.`, ms: 3000 })
                return
            }
        }
    }

    const deleteVideos = () => {
        let clear = videolist_items.map(song => {
            return song.id
        }).filter(id => selected_video.indexOf(id) === -1)
        let videolist_items_update = Array.from(videolist_items).filter(song => selected_video.indexOf(song.id) === -1)
        setVideolistItems([...videolist_items_update])
        addVideoToList({ id_videolist: selectedVideolists[0], selected_song: clear, songs: videolist_items_update })
    }

    const addVideos = () => {
        let current = videolist_items.map(song => {
            return song.id
        })
        let compair = true
        selected_video_all.map((el, index) => {
            if (current.indexOf(el) !== -1) {
                compair = false

            }
        })

        let full = [...current, ...selected_video_all]
        let videolist_items_update = [...Array.from(videolist_items), ...videolist_items_all.filter(song => selected_video_all.indexOf(song.id) !== -1)]


        if (compair === false) {
            showAlertError({ text: `Выбранное видео уже есть в текущем списке`, ms: 3000 })
            return
        }
        setVideolistItems([...videolist_items_update])
        addVideoToList({ id_videolist: selectedVideolists[0], selected_song: full, songs: videolist_items_update })
    }

    return (
        <>  <DragDropContext onDragEnd={handleResult}>
            {!!currentVideolist && <><div style={{ marginTop: -62 }}>
                <ElementsGroup position="right">

                    <ButtonCircle

                        className="plus"
                        disabled={false}
                        onClick={() => openModal({
                            type: "videoCreate",
                            // size: "sm",
                        })}
                    >
                        <SvgPlus />
                    </ButtonCircle>

                    <Button
                        type="string"
                        disabled={(selected_video[0] && !selected_video[1]) ? false : true}
                        onClick={() => {

                            let video = videolist_items.find(item => item.id === selected_video[0]);

                            openModal({
                                type: "videoEdit",
                                data: { video }
                            })
                        }
                        }
                    >
                        <SvgEdit /> Редактировать
                    </Button>

                    <Button
                        type="string"
                        disabled={selected_video.length > 0 ? false : true}
                        onClick={() => deleteVideos()}
                    >
                        <Trash /> Удалить из списка
                    </Button>
                    <Button
                        type="string"
                        onClick={() => downloadVideos({ videosIds: selected_video })}
                        disabled={selected_video.length > 0 ? false : true}
                    >
                        <SvgDownload />
                        Скачать
                    </Button>
                    <Button
                        type="string"
                        onClick={() => selectAllS()}
                    // disabled={ Object.keys(currentVideolist.songs).length > 0 ? false : true }
                    >
                        <Ok size={`xs`} /> Выделить все строки
                    </Button>
                </ElementsGroup>
            </div>
                <div className="Songs">
                    <header className="Songs__control">
                        <div className="Songs__grid">
                            <div className="id">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("id")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    ID
                                </button>
                            </div>
                            <div className="composition_video">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("name")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    ПРОИЗВЕДЕНИЕ
                                </button>
                            </div>
                            <div className="duration_video">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("duration")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    ДЛИТЕЛЬНОСТЬ
                                </button>
                            </div>
                            {/* <div className="type_video">
                            <button className="spp-btn spp-btn_string">
                                <SvgArrowUpDown className="spp-svg"/>
                                ТИП
                            </button>
                        </div> */}
                            <div className="added_video">
                                <button
                                    className="spp-btn spp-btn_string"
                                    onClick={() => sort("added")}
                                >
                                    <SvgArrowUpDown className="spp-svg" />
                                    ДОБАВЛЕНО
                                </button>
                            </div>
                        </div>
                    </header>
                    < div>
                        {/* { videolist_items.length > 0 */}
                        <List
                            type="simple"
                            scroll={{
                                on: true,
                                data: {
                                    variant: "white",
                                    size: "lg",
                                }
                            }}
                            onClick={toggleSelectedS}
                            content={{ items: getItems(), variant: "none" }}
                            // droppableId={ `ownVideolistSongs` }
                            droppableId={`2`}
                        />

                        {/* : <Empty title="В этом плейлисте пока нет музыки." /> */}
                        {/* } */}

                    </div>
                </div>
            </>}
            <div style={{ width: "100%", marginTop: 36 }} className="All_media">
                <AllVideolists />
                <AllVideos
                    videolist_items_all={videolist_items_all}
                    videolist_content_all={videolist_content_all}
                    addVideos={addVideos}
                    selectAll={selectAllall}
                />

            </div>
        </DragDropContext>
        </>

    )
}

const stateToProps = state => ({
    selectedVideolists: [...state.videolists.selected],
    currentVideolist: state.videolists.currentVideolist,
    currentVideolistAll: state.videolists_all.allVideolists.filter(cur => cur.id === state.videolists_all.selected[0])[0],
    selected_all_songs: state.videolists_all.songs,
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.videolists.selected],
    selected_video: state.videolists.selected_video,
    selected_video_all: state.videolists_all.selected_video,
    selected_all: state.videolists_all.selected,
})
const actionsToProps = {
    toggleSelectedVideo,
    toggleSelectedVideoAll,
    updateCurrentMark,
    showAlertSuccess,
    showAlertError,
    openModal,
    closeModal,
    addVideoToList,
    downloadVideos,
    sortVideos,
    loadVideolistsAllById
}

export default connect(stateToProps, actionsToProps)(VideoList)