import { DO_LOGOUT } from '../Login/actions'
import {PUT_OBJECTS_TAGS, LOADING, TOGGLE_SELECTED_TAGS, FILTER_BY_TAG_ID} from './actions'

const initialState = {
    loading: true,
    tags: [],
    tagsAssoc: {},
    tagsAssocIds: {},
    selected: [],
    filteredTagId: null,
}

const reducer = (state = initialState, { type, payload }) => {
    switch(type) {

        case LOADING:
            return {
                ...state,
                loading: payload
            }

        case PUT_OBJECTS_TAGS:
            return {
                ...state,
                tags: payload.tags,
                tagsAssoc: payload.tagsAssoc,
            }

        case TOGGLE_SELECTED_TAGS:
            return {
                ...state,
                selected: [].concat(payload.selected)
            }

        case FILTER_BY_TAG_ID:
            return {
                ...state,
                filteredTagId: payload.tagId
            }
            case DO_LOGOUT:
                return initialState;
    }
    return state
}

export default reducer