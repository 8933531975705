import React from 'react'
import {connect} from 'react-redux'
import {hideAlertError} from '../../store/Alert/actions'

import outside from '../../utilities/outside'

const cyrRegexp = /[а-яё]/i


const AlertError = ({ text, errors, show, ms, hideAlertError }) => {
    if(show) {

        setTimeout(() => hideAlertError(), ms)
        let errorStrings = []
        if(errors && errors[0]) {
             errorStrings = errors.map(error => {
                console.log({error})

                // Вывод сообщения об ошибке, полученной с сервера, если она содержит кириллические символы
                if(typeof error.error === "string") {
                    if(cyrRegexp.test(error.error)){
                        text = error.error
                        return error.error
                    }
                        
                }
                try{            
                    if(error?.response?.status === 403){
                        text = null
                    }        
                    return error?.response?.data?.error
                }catch(e){
                    console.log('Alert error', e);
                }

            })

            errors.map(error => {
                console.log({error})

                // Вывод сообщения об ошибке, полученной с сервера, если она содержит кириллические символы
                if(typeof error.error === "string") {
                    if(cyrRegexp.test(error.error))
                        text = error.error
                }
            })
        }        

        return(
            <>
                {outside(
                    <div className="Alert AlertError">
                        {text}
                        {
                            errorStrings.map(el =>
                                <div>{el}</div>
      
                            )
                        }
                    </div>
                )}
            </>
        )
    } else {
        return <></>
    }
}

const stateToProps = state => ({
    show: state.alert.error.show,
    text: state.alert.error.text,
    errors: state.alert.error.errors,
    ms: state.alert.error.ms
})

const actionsToProps = {
    hideAlertError
}

export default connect(stateToProps, actionsToProps)(AlertError)