import { DO_LOGOUT } from '../Login/actions'
import {LOADING, LOADING_TEXT} from './actions'

const initialState = {
    user: {},
    loading: false,
    loadingText: '',
}

const reducer = (state = initialState, {type, payload}) => {
    //console.log(state, type, payload);
    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case LOADING_TEXT:
            return {
                ...state,
                loadingText: payload
            }
            case DO_LOGOUT:
                return initialState;
    }
    return state
}

export default reducer