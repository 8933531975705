import { DO_LOGOUT } from '../Login/actions';
import { CHANGE_COLLAPSIBLE_VaLUE } from './actions'

const initialState = {
    'objectsGroup': false    
}

const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case CHANGE_COLLAPSIBLE_VaLUE:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        
            case DO_LOGOUT:
                return initialState;
        }

    return state
}

export default reducer