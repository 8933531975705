import { takeEvery, put, select } from 'redux-saga/effects'
import { RELOAD_VALUE, changeValue, putFounded } from '../../store/Search/actions'

export default function* () {
    yield takeEvery(RELOAD_VALUE, worker)
}

function* worker({ payload }) {


    const lastUpdated = yield select(state => payload || state.search.lastUpdated)


    console.log({ RELOAD_VALUE, lastUpdated, payload });

    if (lastUpdated !== "tracks" || lastUpdated !== "objects") {
        return;
    }



    const value = yield select(state => state.search[lastUpdated].value)



    yield put(changeValue({
        type: lastUpdated,
        value
    }))
}