import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS, Spinner as SpinnerBS } from 'react-bootstrap'

import { cl } from '../../../utilities/cl'
import { normalizeNumberSpaces } from '../../../utilities/normalize'

import { editVideo } from '../../../store/Modal/types/VideoCreate/actions'

import Header from '../components/header'

import Uploader from '../../Uploader'
import Select from '../../Form/Select'
import InputText from '../../Form/InputText'
import WarningRed from '../../SVG/WarningRed'

class VideoEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filename: false,
            name: false,
            description: false,
            sumbitDisabled: true,
            list: [],
            track: {
                name: "—",
                size: "—",
                duration: "-"
            }
        }

        this.hSubmit = this.hSubmit.bind(this)
        this.hLoadfile = this.hLoadfile.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.validateForm = this.validateFrom.bind(this)
        this.allowedTypes = [".mp4", ".3gp", ".mkv", ".avi", ".wmv", ".mpeg", ".mpga", ".mpgv",
            ".mp3", ".aac", ".wav", ".flac", ".ogg",
            ".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"]
        this.setVal = this.setVal.bind(this)
        this.convertFile = this.convertFile.bind(this)

    }

    hLoadfile(files) {
        console.log("    hLoadfile(files) {            ", { files });
        if (Array.isArray(files) && files.length === 1) {
            files = files[0]
        }

        if (!Array.isArray(files)) {
            this.setState((state) => {
                return { list: [] };
            });
            let file = files
            const fileName = (file.name);
            const name = fileName.substring(0, fileName.lastIndexOf('.'));
            if (file.type !== "image/jpeg") {
                const url = URL.createObjectURL(file);
                const media = new Audio(url);
                media.onloadedmetadata = () => {
                    this.setState(() => ({
                        list: [],
                        filename: file,
                        name: name,
                        track: {
                            name: fileName,
                            size: file.size,
                            duration: media.duration
                        }
                    }), this.validateFrom)
                    URL.revokeObjectURL(url);
                };
                media.onerror = () => {
                    this.setState(() => ({
                        filename: file,
                        name: name,
                        track: {
                            name: fileName,
                            size: file.size
                        }
                    }), this.validateFrom)
                }
            } else {
                this.setState(() => ({
                    filename: file,
                    name: name,
                    track: {
                        name: fileName,
                        size: file.size,
                        duration: "--:--"
                    }
                }), this.validateFrom)
            }
            //this.hInputText({name: 'name', value: name}, undefined);
        } else {
            this.setState(() => ({
                filename: '',
                name: '',
                track: {
                    name: '',
                    size: '',
                    duration: ''
                }
            }));
            [...files].forEach((currentFile, index) => {
                this.convertFile(currentFile, index);
            })
        }
    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
    }

    hSubmit() {
        this.props.editVideo({
            id: this.state.id,
            data: {
                name: this.state.name,
                description: this.state.description || ""
            },
            current_videolist: this.props.selected_videolist[0]
        })
    }

    validateFrom() {
        if ((this.state.filename
            && (typeof this.state.name === "string" && this.state.name.length > 1)
            || this.state.list.length >= 2)
            // && (typeof this.state.description === "string" && this.state.description.length > 3)
        ) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
        //console.log(this.state)
    }

    setVal(index, attr, val) {
        // console.log(index, attr, val);
        this.setState((state) => {
            const list = state.list;

            list[index][attr] = val;
            return { list };
        });
    }

    convertFile(file, index) {
        const fileName = (file.name);
        const name = fileName.substring(0, fileName.lastIndexOf('.'));
        if (file.type !== "image/jpeg") {
            const url = URL.createObjectURL(file);
            const media = new Audio(url);
            media.onloadedmetadata = () => {
                this.updateList(file, name, index, media)
                URL.revokeObjectURL(url);
            };
            media.onerror = () => {
                this.updateList(file, name, index)
            }
        } else {
            this.updateList(file, name, index)
        }
    }

    updateList(file, name, index, media) {
        const fileObj = {
            filename: file,
            name: name,
            progress: 0,
            status: 0,
            error: '',
            track: {
                name: file.name,
                size: file.size,
                duration: media && typeof media.duration !== 'undefined' ? media.duration : '-'
            }
        }
        this.setState((state) => {
            const list = state.list;
            list[index] = fileObj;
            return { list };
        }, this.validateFrom);
    }



    componentDidMount() {
        // this.props.update()
        console.log({ props: this.props });

        this.setState({ ...this.state, ...this.props.video })
    }

    render() {
        // let videolist_songs = this.props.allVideolists.filter(list => list.id === this.props.selected_videolist[0])[0].songs.map(song => song.id)
        // videolist_songs = videolist_songs[0].songs.map(song => song.id)
        return (
            <div className="ModalVideoEdit">
                <Header
                    title="Редактирование видеоролика"
                    toggleOpened={this.props.toggleOpened}
                />

                <div className="ModalDoubleGrid">

                    <InputText
                        label="Название"
                        comment="* Внимание! Если имя файла не задано, звук будет импортирован из выбранного по-умолчанию"
                        attrs={{
                            name: "name",
                            value: this.state.name,
                            placeholder: "Название ролика"
                        }}
                        onChange={this.hInputText}
                    />
                    <InputText
                        label="Описание"
                        className="description"
                        attrs={{
                            name: "description",
                            placeholder: "Текст описания"
                        }}
                        onChange={this.hInputText}
                    />
                    {/* <Select
                                label="Привязать фоновый контент"
                                type="options"
                                placeholder="Не привязывать"
                                options={ [
                                    { title: "Не привязывать", value: 0 },
                                    { title: "Tasty cherry juice premium.mpeg4", subtitle: "Mpeg4 file", value: 1 },
                                    { title: "Motion is the inherent essence of entire life.avi", subtitle: "AVI file", value: 2 },
                                    { title: "Background image_2.png", subtitle: "PNG file", value: 3 },
                                    { title: "Background image.jpeg", subtitle: "JPEG file", value: 4 },
                                    { title: "Background image_3.png", subtitle: "PNG file", value: 5 },
                                ] }
                            /> */}

                </div>

                <div className="Modal__footer">
                    <ButtonBS onClick={this.hSubmit} variant="primary"
                        disabled={this.state.sumbitDisabled || this.props.loading}>
                        {this.props.loading ? "Сохранение..." : "Сохранить"}
                    </ButtonBS>
                </div>
            </div>
        )
    }
}


const stateToProps = state => {
    const found = state.videolists.currentVideolist;
    const songs = found && found.songs ? found.songs.map(song => song.id) : [];
    return {
        loading: state.modalAdsCreate.loading,
        selected_videolist: state.videolists.selected,
        allVideolists: state.videolists.allVideolists,
        songs: songs
    }
};

const actionsToProps = {
    editVideo
}


function convertTime(time) {
    if (typeof time !== "number") {
        return "--:--";
    }
    var mins = Math.floor(time / 60);
    if (mins < 10) {
        mins = '0' + String(mins);
    }
    var secs = Math.floor(time % 60);
    if (secs < 10) {
        secs = '0' + String(secs);
    }

    return mins + ':' + secs;
}

function normalizeSize(size) {
    if (typeof size === "number") {
        size = Math.ceil(size / 1000)
        size = `${size.toLocaleString()} КБ`
    }
    return size
}


export default connect(stateToProps, actionsToProps)(VideoEdit)