import {
    LOAD_VIDEOLISTS_ALL,
    SORT_ALL_VIDEOS,
    SORT_ALL_VIDEOLISTS,
    LOADING,
    DELETE_VIDEOLISTS_ALL,
    PUT_VIDEOLISTS_ALL,
    TOGGLE_SELECTED_VIDEOLIST_ALL,
    TOGGLE_SELECTED_VIDEO_ALL,
    PUT_VIDEOLISTS_ALL_SONGS,
    UPDATE_ITEM,
    UPDATE_SONG
} from './actions'

import { UPDATE_PLAYLIST } from '../ContentPlaylists/actions'
import { PUT_VIDEOLISTS } from '../ContentVideolists/actions';
import { DO_LOGOUT } from '../Login/actions';

const inititalState = {
    loading: false,
    allVideolists: [],
    selected: [],
    songs: [],
    selected_video: []
}

const reducer = (state = inititalState, { type, payload }) => {


    switch (type) {
        // case UPDATE_PLAYLIST:
        //     return {
        //         ...state,
        //         allVideolists:
        //             state.allVideolists.map(playlist => {
        //                 if (playlist.id === payload.playlist.id) {
        //                     playlist.songs = payload.playlist.songs;
        //                     if (typeof payload.playlist.totalcount !== 'undefined') {
        //                         playlist.totalcount = payload.playlist.totalcount;
        //                     }
        //                     if (typeof payload.playlist.totalsize !== 'undefined') {
        //                         playlist.totalsize = payload.playlist.totalsize;
        //                     }
        //                     if (typeof payload.playlist.totaltime !== 'undefined') {
        //                         playlist.totaltime = payload.playlist.totaltime;
        //                     }
        //                     if (typeof payload.playlist.totalsize2 !== 'undefined') {
        //                         playlist.totalsize2 = payload.playlist.totalsize2;
        //                     }
        //                 }
        //                 console.log({ playlist });
        //                 return playlist
        //             })
        //     }

        // case PUT_VIDEOLISTS:
        //     return {
        //         ...state,
        //         allVideolists: payload.videolists
        //     }

        case UPDATE_ITEM: 
            return {
                ...state,
                allVideolists: state.allVideolists.map(item => item.id === payload.id ? payload : item)
            }
        

        case UPDATE_SONG:
            return {
                ...state,
                songs: state.songs.map(item => item.id === payload.id ? payload : item)
            }


        case SORT_ALL_VIDEOS:
            if (payload.sort_type === "name") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).map(videolist => {
                            if (videolist.id === payload.id) {
                                videolist.songs.sort((a, b) => {
                                    if (a.name < b.name) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.name > b.name) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                return { ...videolist }
                            } else {
                                return { ...videolist }
                            }
                        })
                }
            } else if (payload.sort_type === "duration") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).map(videolist => {
                            if (videolist.id === payload.id) {
                                videolist.songs.sort((a, b) => {
                                    if (a.cliptime < b.cliptime) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.cliptime > b.cliptime) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                return { ...videolist }
                            } else {
                                return { ...videolist }
                            }
                        })
                }
            } else if (payload.sort_type === "added") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).map(videolist => {
                            if (videolist.id === payload.id) {
                                videolist.songs.sort((a, b) => {
                                    if (a.ctime < b.ctime) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.ctime > b.ctime) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                return { ...videolist }
                            } else {
                                return { ...videolist }
                            }
                        })
                }
            } else if (payload.sort_type === "id") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).map(videolist => {
                            if (videolist.id === payload.id) {
                                videolist.songs.sort((a, b) => {
                                    if (a.id < b.id) {
                                        return payload.direction === "up" ? -1 : 1
                                    }
                                    if (a.id > b.id) {
                                        return payload.direction === "up" ? 1 : -1
                                    }
                                    return 0
                                })
                                return { ...videolist }
                            } else {
                                return { ...videolist }
                            }
                        })
                }
            }
        case SORT_ALL_VIDEOLISTS:
            if (payload.sort_type === "name") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).sort((a, b) => {
                            if (a.name < b.name) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (a.name > b.name) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            } else if (payload.sort_type === "songs") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).sort((a, b) => {

                            if (Object.keys(a.songs).length < Object.keys(b.songs).length) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (Object.keys(a.songs).length > Object.keys(b.songs).length) {
                                return payload.direction === "up" ? 1 : -1
                            }
                            return 0
                        }),
                }
            }
            else if (payload.sort_type === "size" || payload.sort_type === "duration") {
                return {
                    ...state,
                    allVideolists:
                        Array.from(state.allVideolists).sort((a, b) => {
                            if (Object.values(a.songs).reduce((accumulator, currentValue) => accumulator + currentValue.size, 0) < Object.values(b.songs).reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)) {
                                return payload.direction === "up" ? -1 : 1
                            }
                            if (Object.values(a.songs).reduce((accumulator, currentValue) => accumulator + currentValue.size, 0) > Object.values(b.songs).reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)) {
                                return payload.direction === "up" ? 1 : -1
                            } else {
                                return 0
                            }
                        }),
                }
            }
        case LOAD_VIDEOLISTS_ALL:
            return {
                ...state,
                allVideolists: [],
                selected: [...state.selected]
            }
        case PUT_VIDEOLISTS_ALL:
            return {
                ...state,
                allVideolists: payload.videolists,
                //tracksAssoc: payload.tracksAssoc,
            }

        case PUT_VIDEOLISTS_ALL_SONGS:
            return {
                ...state,
                songs: payload,
            }

        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case DELETE_VIDEOLISTS_ALL:
            return {
                ...state,
                loading: true,
                selected: []
            }
        case TOGGLE_SELECTED_VIDEOLIST_ALL:
            const { id, selected, simple, required } = payload
            if (simple === true) {
                return {
                    ...state,
                    selected,
                }
            } else if (required === true) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected
                }
            } else if (required === false) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) !== -1)
                            ? state.selected.filter(playlistId => playlistId !== id)
                            : state.selected
                }
            } else {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected.filter(playlistId => playlistId !== id)
                }
            }
        case TOGGLE_SELECTED_VIDEO_ALL:
            const { id_video, selected_video, onlyOne, setAll } = payload
            if (setAll === true) {
                return {
                    ...state,
                    selected_video: id_video,
                }
            } else if (selected_video === true) {
                return {
                    ...state,
                    selected_video:
                        (state.selected_video.indexOf(id_video) === -1)
                            ? state.selected_video.concat([id_video])
                            : state.selected_video
                }
            } else if (selected_video === false) {
                return {
                    ...state,
                    selected_video:
                        (state.selected_video.indexOf(id_video) !== -1)
                            ? state.selected_video.filter(objectId => objectId !== id_video)
                            : state.selected_video
                }
            } else {
                if (onlyOne === true) {
                    return {
                        ...state,
                        selected_video:
                            (state.selected_video.indexOf(id_video) === -1)
                                ? [id_video]
                                : []
                    }
                } else {
                    return {
                        ...state,
                        selected_video:
                            (state.selected_video.indexOf(id_video) === -1)
                                ? state.selected_video.concat([id_video])
                                : state.selected_video.filter(objectId => objectId !== id_video)
                    }
                }
            }

            case DO_LOGOUT:
                return inititalState;

    }
    return state
}

export default reducer