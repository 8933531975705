import moment from 'moment'
import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_TRACKS, loading, putTracks } from '../../store/Tracks/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { changeValue } from '../../store/Search/actions'

export default function* () {
    yield takeEvery(LOAD_TRACKS, worker)
}

function* worker(action) {
    const params = action.payload
    const theme = params && params.theme ? params.theme : false;
    yield put(loading(true))
    try {
        let tracks = [],
            tracksAssoc = {}
        const fetchedTracks = yield call(fetchTracks, params)

        if (fetchedTracks.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_TRACKS}.`, fetchedTracks],
                text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putTracks({ tracks, tracksAssoc }))

        } else {
            const ft = fetchedTracks.success

            if (ft[0]) {
                const today = Number(moment(new Date()).format("x"))
                // const clips = yield all(
                //     ft.map(track => call(fetchClips, track.id))
                // )

                ft.map((track, index) => {
                    track.ctime_d = moment(track.ctime).format("DD.MM.Y");
                    track.ctime_t = moment(track.ctime).format("HH:m:s");
                    track.clipEnded = track.outdate;
                    track.clipDeleted = track.deleted || false;
                    // Иконка
                    if (track.clipEnded || track.clipDeleted) {
                        track.trackWarning = true
                    }

                    // Ссылка на файл
                    track.filename = buildGETUrl("clip/play", { id: track.id })

                    tracks.push(track)
                })
            } else {
                tracks = ft
            }

            tracks.map(track => tracksAssoc[track.id] = track)

            yield put(putTracks({ tracks, tracksAssoc, theme }))
            const value = yield select(state => state.search.tracks.value)
            yield put(changeValue({
                type: 'tracks',
                value
            }))

        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_TRACKS}.`, e],
            text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putTracks({ tracks: [], tracksAssoc: {} }))
    }

    finally {        
        yield put(loading(false))        
    }
}

function fetchTracks(params) {
    let link = "/clip/select"
    const getParams = {}
    if (params && params.theme) {
        getParams['owner_id'] = ''
        if (params.theme === 'extra') {
            link = "/clip/extra/select"
        }
    }
    return axios.get(link, { params: getParams })
        .then(response => response.data)
}

function fetchTracksExtra(params) {
    const getParams = { 'owner_id': '' }



    return axios.get("/clip/extra/select", { params: getParams })
        .then(response => response.data)
}

function fetchClips(trackId) {
    return axios.get("/timeline/clip/select", {
        params:
            { indent_id: trackId }
    })
        .then(response => response.data)
}