import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ScrollContainer from '../../../../../components/ScrollContainer'
import Spinner from '../../../../../components/Spinner'
import VideolistAllItem from '../../../../../components/ContentVideo/VideolistAllItem'
import Empty from '../PlaylistSongs/Empty'
import List from '../../../../../components/List'
import { loadVideolistsAll, toggleSelectedVideolistAll, sortAllVideolists } from '../../../../../store/ContentAllVideolists/actions'
import { isMobile } from '../../../../../utilities/media'
import { getNextSelected } from '../../../../../utilities/getNextSelected'
import Button from '../../../../../components/Button'
import SvgArrowUpDown from '../../../../../components/SVG/ArrowUpDown'

const AllVideolists = ({
    searchActive,
    searchFounded = [],
    videolists = [],
    toggleSelected,
    allVideolists,
    loadVideolistsAll,

    selectedVideolists,
    toggleSelectedVideolistAll,
    selected,
    dnd_result,
    loading,
    sortAllVideolists

}) => {
    const [sort_direction, setSortDirection] = useState("")


    const sort = (type) => {
        setSortDirection(sort_direction === "up" ? "down" : "up")
        sortAllVideolists({ direction: sort_direction, sort_type: type })
    }
    useEffect(() => {
        loadVideolistsAll()
    }, [])
    //if (searchActive) allPlaylists = searchFounded
    videolists = allVideolists
    console.log(":::::::::::::::::::::::::::::");
    const hToggleSelected = (e) => {
        const videolist = e.target.closest(".Videolist")
        if (videolist) {
            const videolistId = Number(videolist.dataset.videolistId)

            if (!isNaN(videolistId)) {
                const selected = getNextSelected({
                    multiple: true,
                    itemId: videolistId,
                    items: allVideolists,
                    selected: selectedVideolists,
                    shiftKey: e.shiftKey,
                    ctrlKey: isMobile() || e.ctrlKey || e.metaKey,
                })

                toggleSelectedVideolistAll({
                    selected,
                    simple: true,
                })
            }
        }
    }



    const getItems = () => {
        return allVideolists.map((videolist, index) => ({
            id: videolist.id,
            name: videolist.name,
            element: < VideolistAllItem
                key={ videolist.id }
                id={ videolist.id }
                name={ videolist.name }
                totalcount={ videolist.totalcount }
                totalsize={ videolist.totalsize2 }
                totaltime={ videolist.totaltime }
                songs={ videolist.songs }
                variant={ (index % 2 == 0) ? "gray" : "" }
            />
        }))
    }

    return (
        <section className="Videolists" style={ { width: "37%" } }>
            <h2>ВСЕ СПИСКИ</h2>
            <div onClick={ hToggleSelected } style={ { marginTop: 34 } }>
                <header className="Videolists__control">
                    <div className="Videolists__grid">
                        <div className="name_public">
                            <Button
                                type="string"
                                onClick={ () => sort("name") }
                            >
                                <SvgArrowUpDown title="Сортировка по название плейлиста" />
                                НАЗВАНИЕ
                            </Button>
                        </div>
                        <div className="tracks_count_public">
                            <Button
                                type="string"
                                onClick={ () => sort("songs") }
                            >
                                <SvgArrowUpDown title="Сортировка по кол-ву треков" />
                                ТРЕКОВ
                            </Button>
                        </div>
                        <div className="size_public">
                            <Button
                                type="string"
                                onClick={ () => sort("size") }
                            >
                                <SvgArrowUpDown title="Сортировка по размеру плейлиста" />
                                РАЗМЕР
                            </Button>
                        </div>
                        <div className="duration_public">
                            <Button
                                type="string"
                                onClick={ () => sort("duration") }
                            >
                                <SvgArrowUpDown title="Сортировка по длительности" />
                                ДЛИТ.
                            </Button>
                        </div>
                    </div>
                </header>
                { loading
                    ? <Spinner className="pt-xs pb-xs" />
                    : videolists.length > 0
                        ? <List
                            type="simple"
                            scroll={ {
                                on: true,
                                data: {
                                    variant: "white",
                                    size: "lg",
                                }
                            } }
                            // variant={ "none" }
                            content={ { items: getItems(), variant: "none" } }
                        />

                        : <Empty title="Плейлистов пока нет." />
                }

            </div>
        </section>
    )
}

const stateToProps = state => ({
    allVideolists: state.videolists_all.allVideolists,
    loading: state.videolists_all.loading,
    selectedVideolists: [...state.videolists_all.selected],
    selected: [...state.videolists_all.selected],

})

const actionsToProps = {
    loadVideolistsAll,
    toggleSelectedVideolistAll,
    sortAllVideolists
}
export default connect(stateToProps, actionsToProps)(AllVideolists)