import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_OBJECTS } from '../../store/Objects/actions'
import { LOAD_OBJECTS_GROUP, putObjectsGroup, putGroupsList, loading } from '../../store/ObjectsGroup/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { objectSort } from "../Objects/loadObjects";

export default function* () {
    yield takeEvery(LOAD_OBJECTS, worker)
    yield takeEvery(LOAD_OBJECTS_GROUP, worker)
}

const initialValues = {
    groups: [],
    groupsList: [],
    groupsAssoc: {}
}

function* worker() {
    let groups = [],
        groupsList = [],
        groupsAssoc = {}

    // Успех
    try {
        let fetchedGroups = yield call(fetchGroups)

        // Успех
        if (fetchedGroups.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_OBJECTS_GROUP}.`, fetchedGroups],
                text: "Группы объектов не были загружены. Попробуйте перезагрузить страницу."
            }))

            // Ошибка
        } else {
            fetchedGroups = fetchedGroups.success

            groups = buildGroups(fetchedGroups)
            groupsList = buildGroupsList(groups)
            groups.map(group => groupsAssoc[group.id] = group)
        }
    }

    // Ошибка
    catch (e) {
        console.log(LOAD_OBJECTS_GROUP, " catch", { e });

        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_OBJECTS_GROUP}.`, e],
            text: "Группы объектов не были загружены. Попробуйте перезагрузить страницу."
        }))
    }

    finally {
        console.log(LOAD_OBJECTS_GROUP, " finaly");

        yield put(loading(false))
        yield put(putObjectsGroup({ groups, groupsAssoc } || initialValues))
        yield put(putGroupsList(groupsList))
    }
}

function fetchGroups() {
    return axios.get("/msgroup/view/select")
        .then(response => response.data)
}

function buildGroups(groups) {
    let builded = []

    for (let groupId in groups) {
        let group = groups[groupId],
            objectsIds = []

        if (group.ms) {
            objectsIds = group.ms.map(object => object.id);
            group.ms = objectSort(group.ms)
        }

        builded.push({
            id: group.id,
            name: group.id ? `${group.id}  ${group.name}` : '',
            name2: group.name,
            objects: group.ms || [],
            objectsIds: objectsIds
        })
    }
    builded.sort((a, b) => a.name2 < b.name2 ? -1 : 1)
    return builded
}

function buildGroupsList(groups) {
    let builded = []

    groups.map(group => {
        builded.push({
            title: group.name,
            value: group.id
        })
    })
    return builded
}