export default [
    "msgroup_id",
    "msstatus_id",
    "tags",
    "name",
    "alias",
    "address",
    "description",
    "chmask",
    "micvol",
    "linevol",
    "extravolume",
    "chmask",
    "isbackon",
    "bpalg",
    "msync",
    "autoupdate",
    "ftpactive",
    "tz",
    "ntpserver",
    "pausealg",
    "dummyad",
    "videorandom",
    "vsdiff",
    "vsip",
    "vsbaseport",
    "notifyto1",
    "notifyto2",
    "notifyblock",
    "warntraffic",
    "maxtraffic",
    "waitbgvideo",
    "streamlink",
    "mute",
    "videolist_id"
]