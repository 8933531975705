import moment from 'moment'
import { takeEvery, call, put, all } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { LOAD_VIDEOLISTS, loading, putVideolists } from '../../store/ContentVideolists/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { updateItem } from '../../store/ContentAllVideolists/actions'

export default function* () {
    yield takeEvery(LOAD_VIDEOLISTS, worker)
}

function* worker({ payload }) {

    const added = payload?.added

    yield put(loading(true))
    try {
        let videolists = []
        const fetchedVideolists = yield call(fetchVideolists)

        if (fetchedVideolists.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_VIDEOLISTS}.`, fetchedVideolists],
                text: "Ролики не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putVideolists({ videolists }))

        } else {
            const fpl = fetchedVideolists.success

            // if (fpl[0]) {
            //     const videos = yield all(
            //         fpl.map(videolist => call(fetchVideo, videolist.id))
            //     )
            //
            //     // alert(JSON.stringify(songs.map(song => song.success)))
            //     videolists = videos.map(video => {
            //         video.success = { ...video.success[0], songs: video.success.songs }
            //         Object.values(video.success.songs).map(track => {
            //             track.filename = buildGETUrl("clip/play", { id: track.id })
            //         })
            //         return video.success
            //     })
            // }
            //playlists = fpl
            //alert(JSON.stringify(playlists))
            //playlists = playlists.success
            //playlists.map(playlist => songsAssoc[playlist.id] = playlist)

            let updatedList

            const videolists = fpl.map(videolist => {
                let totalsize2 = 0;
                if (videolist.totalsize) {
                    let size = Math.floor(videolist.totalsize / 1024 / 1024);
                    size = !size ? `${Math.floor(videolist.totalsize / 1024)} Kb` : `${size} Mb`
                    totalsize2 = size
                }
                videolist.totalsize2 = totalsize2

                if (!!added && videolist.id === added) {
                    updatedList = { ...videolist }
                }
                return videolist;
            })
            yield put(putVideolists({ videolists }))

            console.log({ updatedList });

            if (!!updatedList) {
                
                yield put(updateItem(updatedList))
            }
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_VIDEOLISTS}.`, e],
            text: "Плейлисты не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putVideolists({ videolists: [] }))
    }

    finally {
        yield put(loading(false))
    }
}

export function fetchVideolists() {
    return axios.get("/videolist/select")
        .then(response => response.data)
}

function fetchVideo(videolistId) {
    return axios.get("/videolist/get", {
        params:
            { id: videolistId }
    })
        .then(response => response.data)
}