import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { arraysHasDifferences } from '../../../../../utilities/arraysHasDifferences'

import { loadReport } from '../../../../../store/Modal/types/AdsReport/actions'

import Header from '../../../components/header'
import ToggleDate from './ToggleDate'
import Button from '../../../../Button'
import Select from '../../../../Form/Select'
import Spinner from '../../../../Spinner'

import SvgArrowUpDown from '../../../../SVG/ArrowUpDown'
import Datepicker from "../../../../Form/Datepicker";
import SvgDownload from "../../../../SVG/Download";
import { buildGETUrl } from "../../../../../utilities/buildGETUrl";
import { download } from "../../../../../utilities/download";
import InputText from '../../../../Form/InputText'

class AdsReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //objects: [],
            // objectId: '',
            from: moment().format("YYYY[-]MM[-]DD"),
            to: moment().format("YYYY[-]MM[-]DD"),
            place: "inside",
            searchName: '',
            report: {
                inside: [],
                outside: [],

            }
        }

        this.toggleDate = this.toggleDate.bind(this)
        this.toggleObject = this.toggleObject.bind(this)
        this.updateStateFromProps = this.updateStateFromProps.bind(this)
        this.downloadReport = this.downloadReport.bind(this)
        this.handleSearchNameChange = this.handleSearchNameChange.bind(this)
    }


    componentDidMount() {
        // console.log('mounted', this.props.objectId, this.state.from, this.state.to)
        this.props.loadReport({
            //objectId: this.props.objectId,
            // from: this.state.from,
            // to: this.state.to,
        })
        // this.updateStateFromProps()
    }

    componentDidUpdate(prevProps) {
        let updated = false
        if (prevProps.objectId !== this.props.objectId) updated = true
        // if (prevProps.from !== this.props.from) updated = true
        // if (prevProps.to !== this.props.to) updated = true
        // if (arraysHasDifferences(prevProps.reportInside, this.props.reportInside)) updated = true
        // if (arraysHasDifferences(prevProps.reportOutside, this.props.reportOutside)) updated = true
        //console.log(updated)
        if (updated) {
            // this.updateStateFromProps()
            // this.props.loadReport({
            //     objectId: this.props.objectId,
            //     from: this.state.from,
            //     to: this.state.to,
            // })
        }
    }

    handleSearchNameChange(e) {
        this.setState({ searchName: e.value })
    }

    updateStateFromProps() {
        this.setState({
            // objects: this.props.objects,
            // objectId: this.props.objectId,
            // from: this.props.from,
            // to: this.props.to,
            // report: {
            //     inside: this.props.reportInside,
            //     outside: this.props.reportOutside,
            // }
        })
    }

    togglePlace(place) {
        this.setState({ place })
    }

    toggleObject({ value }) {
        // this.setState({objectId: value})
        this.props.loadReport({
            objectId: value,
            from: this.state.from,
            to: this.state.to,
        })
    }

    onSearchNameChanged(searchName) {
        this.setState({ searchName })
    }

    toggleDate({ from, to }) {
        if (from && to && this.props.objectId) {
            this.setState({ from, to })
            this.props.loadReport({
                //objectId: this.props.objectId,
                from,
                to,
            })
        }
    }

    downloadReport(objectId) {
        const url = buildGETUrl('docs/report/ad', { ms_id: objectId, from: this.state.from, to: this.state.to })
        download(url)
    }

    render() {
        const {
            objectId,
            report,
            object,
            // from,
            // to,
            loading,
            objects,
            toggleOpened
        } = this.props
        const {
            from,
            to,
            place,
            searchName
            // objects,
            // objectId,
            // report
        } = this.state




        return (
            <div className={`ModalAdsReport ModalAdsReport_${place}`}>
                <Header
                    title={"Эфир за:"}
                    toggleOpened={toggleOpened}
                    title={object && !loading ? object['alias'] || object['name'] || 'UPS' : ''} />
                <Header
                    title={"Эфир за:"}
                    hideCloseButton
                    addedTitleElement={
                        <>
                            <Datepicker
                                type="between"
                                variant="icon"
                                onChange={this.toggleDate}
                                //disabled={ this.state.allPeriod }
                                titles={{
                                    main: "Период",
                                    from: "Дата начала",
                                    to: "Дата окончания",
                                }}
                                data={{
                                    oneDate: false,
                                    default: {
                                        from,
                                        to,
                                    },
                                    //disabledType: "future",
                                }}
                            />
                            <Button onClick={() => this.downloadReport(objectId)} type="string" className="ml-4">
                                <SvgDownload />
                                Скачать
                            </Button>
                        </>

                    }
                />
                <div className="ModalAdsReport__header">
                    <div className="ModalAdsReport__toggle-object row d-flex align-items-end">
                        <Select
                            label="Объект"
                            type="options"
                            placeholder="Выберите объект"
                            onChange={this.toggleObject}
                            selected={objectId}
                            options={objects}
                        />
                        <InputText
                            attrs={{
                                name: "PlayListSearch",
                                placeholder: "Поиск по названию",
                                value: searchName
                            }}
                            className="ml-3"
                            onChange={this.handleSearchNameChange}
                        />
                    </div>
                    <div className="ModalAdsReport__toggle-place">
                        <div
                            className={`${place === "inside" ? "active" : ""}`}
                            onClick={() => {
                                this.togglePlace("inside")
                            }}
                        >
                            Помещение
                        </div>
                        <div
                            className={`${place === "outside" ? "active" : ""}`}
                            onClick={() => {
                                this.togglePlace("outside")
                            }}
                        >
                            Улица
                        </div>
                    </div>
                </div>

                <div className="ModalAdsReport__report">
                    <div className="header">
                        <div className="ModalAdsReport__grid grids-titles">
                            <div className="start">
                                <Button type="string">
                                    <SvgArrowUpDown title='Сортировка по времени начала' />
                                    <span className="des">Начало</span>
                                </Button>
                            </div>
                            <div className="title">
                                Название
                            </div>
                            <div className="time">
                                Длит./Ост<span className="mob">.</span><span className="des">алось</span>
                            </div>
                        </div>
                    </div>

                    <div className="reports">
                        {this.props.loading
                            ? <Spinner />
                            : <>
                                <div className="inside">
                                    {this.displayItems(report?.inside, searchName)}
                                </div>
                                <div className="outside">
                                    {this.displayItems(report?.outside, searchName)}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }


    displayItems(items = [], searchName) {
        let prev = '', starttime = ''

        const putItOut = (indentname) => {
            if (searchName === "")
                return false;

            if (!!!indentname)
                return true;

            if (indentname.toLowerCase().indexOf(searchName.toLowerCase()) === -1)
                return true;

            return '';
        }
        let parentIndex = 0;
        let haveOuted = false;

        if (items.length) {
            items = items.map((item, index) => {
                starttime = item.starttime

                if (starttime === prev) {
                    starttime = ''

                } else {
                    parentIndex = index;
                    haveOuted = false;
                }
                // Вычленить первый 
                item.putItOut = false;


                if (searchName !== '') {
                    item.showTime = false;

                    if (putItOut(item.indentname)) {
                        item.putItOut = true;
                    } else {
                        items[parentIndex].showTime = true;
                    }
                } else {
                    item.showTime = true
                }


                prev = item.starttime

                return item;
            })
        }

        return (
            <>
                {items.length
                    ? items.map((item, index) => {

                        // Осталось времени в блоке (Блоком является 5-минутка часа)
                        if (item.final) {
                            return (
                                <div className="report__item final" key={index}>
                                    <div className={`ModalAdsReport__grid`}>
                                        <div className="start"></div>
                                        <div className="title"></div>
                                        <div className="time">
                                            <div className="timeDuration" style={{ opacity: item.putItOut ? 0.25 : 1 }}>{item.sum}</div>
                                            <div className="timeLeft" style={{ opacity: item.putItOut ? 0.25 : 1 }}>{lostTime(item.sum, items[index - 1].blocktime)}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        // Вывод пункта
                        else {
                            starttime = item.starttime
                            if (starttime === prev) {
                                starttime = ''
                            }
                            prev = item.starttime
                            /*isindent = false - это джингл жирный, иначе - обычный ролик*/
                            return (
                                <div className="report__item" key={index}>
                                    <div className="ModalAdsReport__grid">
                                        <div className={`start`} style={{ opacity: !item.showTime ? 0.25 : 1 }}>{starttime}</div>
                                        <div
                                            className={`${!item.isindent ? "title title--bold" : "title"}`} style={{ opacity: item.putItOut ? 0.25 : 1 }}>{item.indentname}</div>
                                        <div className="time">
                                            <div className="timeDuration" style={{ opacity: item.putItOut ? 0.25 : 1 }}>{normalizeThisTime(item.indenttime)}</div>
                                            {item.timeLeft &&
                                                <div className="timeLeft" style={{ opacity: item.putItOut ? 0.25 : 1 }}>{normalizeThisTime(item.blocktime)}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                    : <div className="ModalAdsReport__empty">Нет расписания.</div>
                }
            </>
        )
    }
}

const stateToProps = state => {
    const inside = [...state.modalAdsReport.report.inside]
    const outside = [...state.modalAdsReport.report.outside]

    const objectId = state.modalAdsReport.selectedObject;


    const object = state.objects.allObjects.find(item => item.id === objectId)




    return {
        loading: state.modalAdsReport.loading,
        // objects: [...state.modalAdsReport.objects],
        objects: [...state.objects.allObjects.map(o => {
            return {
                title: o.fullName,
                value: o.id
            }
        })],
        objectId,
        object,
        from: state.modalAdsReport.from,
        to: state.modalAdsReport.to,
        report: state.modalAdsReport.report,
        reportInside: inside,
        reportOutside: outside,
    }
}

const actionsToProps = {
    loadReport
}

export default connect(stateToProps, actionsToProps)(AdsReport)

//
// Функции
//

function normalizeThisTime(time) {
    if (typeof time !== "string") return ""
    return time.split(".")[0]
}

const lostTime = (sumInner, blocktimeInner) => {

    try {
        let sum = moment(sumInner, 'mm:ss');
        let time = moment(blocktimeInner, "HH:mm");

        let isOverSum = false
        let diff
        if (sum > time) {
            diff = moment.duration(sum.diff(time));
            isOverSum = true
        }
        else {
            diff = moment.duration(time.diff(sum))
        }

        return `${isOverSum ? "+ " : ""}` + moment.utc(diff.asMilliseconds()).format('mm:ss')
    } catch (e) {
        return ""
    }
};