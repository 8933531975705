import { DO_LOGOUT } from '../Login/actions'
import {CHANGE_EXPANDED_PLACE, CHANGE_ACTIVE_PLACE} from './actions'

const inititalState = {
    expandedPlace: 0,
    activePlace: 1,
    wereActive: [1]
} 

const reducer = (state = inititalState, action) => {
    switch (action.type) {

        case CHANGE_ACTIVE_PLACE:
            let wereActive = state.wereActive
            if(wereActive.indexOf(action.payload) === -1) wereActive.push(action.payload)
            return {
                ...state,
                activePlace: action.payload,
                wereActive: wereActive
            }

        case CHANGE_EXPANDED_PLACE:
            let next = action.payload === state.expandedPlace ? 0 : action.payload
            return {
                ...state,
                expandedPlace: next
            }

            case DO_LOGOUT:
                return inititalState;    
    }
    return state
}

export default reducer