import { LOAD_TRACKS, PUT_TRACKS, LOADING, TOGGLE_SELECTED_TRACK, TOGGLE_HIDE_ENDED } from './actions'
import { isArr } from "../../utilities/isArr";
import { DO_LOGOUT } from '../Login/actions';

const inititalState = {
    loading: false,
    tracks: [],
    tracksTheme: [],
    tracksExtra: [],
    tracksAssoc: {},
    tracksAssocTheme: {},
    selected: [],
    selectedTheme: [],
    selecedExtra: [],
    hideEnded: true
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case TOGGLE_HIDE_ENDED:
            return {
                ...state,
                hideEnded: payload
            }

        case LOAD_TRACKS:
            return {
                ...state,
                // tracks: [],
                // tracksTheme: [],
                selected: [...state.selected]
            }

        case LOADING:
            return {
                ...state,
                loading: payload,
            }

        case PUT_TRACKS:
            if (payload.theme) {
                if (payload.theme === 'extra')
                    return {
                        ...state,
                        tracksExtra: payload.tracks,
                        tracksAssocTheme: payload.tracksAssoc,
                    };
                return {
                    ...state,
                    tracksTheme: payload.tracks,
                    tracksAssocTheme: payload.tracksAssoc,
                }
            } else {
                return {
                    ...state,
                    tracks: payload.tracks,
                    tracksAssoc: payload.tracksAssoc,
                }
            }

        case TOGGLE_SELECTED_TRACK:
            const { id, selected, onlyOne, ids } = payload
            if (typeof ids !== 'undefined') {
                return {
                    ...state,
                    selected: [...ids]
                }
            }
            if (selected === true) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) === -1)
                            ? state.selected.concat([id])
                            : state.selected
                }
            } else if (selected === false) {
                return {
                    ...state,
                    selected:
                        (state.selected.indexOf(id) !== -1)
                            ? state.selected.filter(objectId => objectId !== id)
                            : state.selected
                }
            } else {
                if (onlyOne === true) {
                    return {
                        ...state,
                        selected:
                            (state.selected.indexOf(id) === -1)
                                ? [id]
                                : []
                    }
                } else {
                    return {
                        ...state,
                        selected:
                            (state.selected.indexOf(id) === -1)
                                ? state.selected.concat([id])
                                : state.selected.filter(objectId => objectId !== id)
                    }
                }
            }
            case DO_LOGOUT:
                return inititalState;

    }
    return state
}

export default reducer