export const UPDATE_OPTIONS        = "Settings/UPDATE_OPTIONS"
export const CLEAN_SETTINGS        = "Settings/CLEAN_SETTINGS"
export const TOGGLE_SELECTED_ITEMS = "Settings/TOGGLE_SELECTED_ITEMS"
export const SAVING                = "Settings/SAVING"
export const LOADING               = "Settings/LOADING"
export const ADDING                = "Settings/ADDING"
export const DELETING              = "Settings/DELETING"
export const MODE                  = "Settings/MODE"

export const updateOptions = ({ type, stateKey, noChanged, settings }) => ({
    type: UPDATE_OPTIONS,
    payload: {
        type,
        stateKey,
        noChanged,
        settings,
    }
})

export const cleanSettings = ({ type, stateKey }) => ({
    type: CLEAN_SETTINGS,
    payload: {
        type,
        stateKey,
    }
})

// 

export const toggleSelectedItems = ({ type, stateKey, itemIds = [0] }) => {
    return {
        type: TOGGLE_SELECTED_ITEMS,
        payload: {
            type,
            stateKey,
            selected: itemIds,
        }
    }
}

export const saving = ({ type, stateKey, saving }) => ({
    type: SAVING,
    payload: {
        type,
        stateKey,
        saving,
    }
})

export const loading = ({ type, stateKey, loading }) => ({
    type: LOADING,
    payload: {
        type,
        stateKey,
        loading,
    }
})

export const adding = ({ type, stateKey, adding }) => ({
    type: ADDING,
    payload: {
        type,
        stateKey,
        adding,
    }
})

export const deleting = ({ type, stateKey, deleting }) => ({
    type: DELETING,
    payload: {
        type,
        stateKey,
        deleting,
    }
})

export const mode = ({ type, stateKey, modeType, mode }) => ({
    type: MODE,
    payload: {
        type,
        stateKey,
        modeType,
        mode,
    }
})