import { takeEvery, call, put, select } from 'redux-saga/effects';
import { EDIT } from '../../../store/Modal/types/VideoCreate/actions';
import { closeModal } from '../../../store/Modal/actions';
import { loadVideolists, loadVideolist, setCurrentVideolist } from '../../../store/ContentVideolists/actions';
import { showAlertError } from '../../../store/Alert/actions';
import axios from 'axios';
import { loadingText, loading } from "../../../store/App/actions";
import { updateSong } from '../../../store/ContentAllVideolists/actions';

const FORM_DATA_KEYS = ['name', 'description', 'filename']


export default function* () {
    yield takeEvery(EDIT, worker);
}

function* worker({ payload }) {
    console.log(EDIT, { payload });

    let { id, data, current_videolist } = payload

    try {

        const videoList = yield select(state => state.videolists.currentVideolist)

        const updated = yield call(fetchUpdate, id, data)




        if (updated.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${EDIT}.`, {}],
                text: "Видео не было Обновлено. Попробуйте повторить."
            }));

        } else {

            yield put(updateSong(updated.success[0]));

            yield put(setCurrentVideolist({
                videolist: {
                    ...videoList,
                    songs: videoList.songs.map(
                        item => item.id === id ?
                            updated.success[0] : item
                    )
                }
            }))
        }



    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${EDIT}.`, e],
            text: "Ролик не был создан. Попробуйте повторить."
        }));
    } finally {
        yield put(loading(false));
        yield put(loadingText(''));
        yield put(closeModal("videoEdit"));
    }
}

function fetchUpdate(id, videoData) {
    let formData = new FormData();
    if (videoData && videoData.name) {
        Object.keys(videoData).forEach(name => {
            if (FORM_DATA_KEYS.includes(name))
                formData.set(name, videoData[name]);
        });
    }

    return axios({
        url: `videolist/tracks/update?id=${id}`,
        method: "POST",
        data: formData,
    })
        .then(response => response.data);
}


function fetchSelect(id) {

    return axios({
        url: `videolist/tracks/select?id=${id}`,
        method: "GET",
    })
        .then(response => response.data);
}

