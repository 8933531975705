export const LOGIN = "App/LOGIN"
export const DO_LOGIN = "App/DO_LOGIN"
export const DO_LOGOUT = "App/DO_LOGOUT"
export const DO_LOGOUT_ACTION = "App/DO_LOGOUT_ACTION"
export const DO_SUB_LOGIN = "App/DO_SUB_LOGIN"
export const LOADING = "App/LOADING"
export const PASS_RECOVERY = "App/PASS_RECOVERY"
export const PUT_RECOVERY = "App/PUT_RECOVERY"
export const GET_USER = "App/GET_USER"

export const Login = data => ({
    type: LOGIN,
    payload: data
})

export const getUser = data => ({
    type: GET_USER,
    payload: data
})

export const doLogin = creds => ({
    type: DO_LOGIN,
    payload: creds
})

export const doLogoutAction = payload => ({
    type: DO_LOGOUT_ACTION,
    payload
})

export const doLogout = creds => ({
    type: DO_LOGOUT
})

export const doSubLogin = creds => ({
    type: DO_SUB_LOGIN,
    payload: creds
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})
export const passRecovery = email => ({
    type: PASS_RECOVERY,
    payload: email
})
export const putRecovery = data => ({
    type: PUT_RECOVERY,
    payload: data
})