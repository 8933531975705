import { takeEvery, select, put, call } from 'redux-saga/effects'
import { OBJECT_BACKGROUND } from '../../../../store/Settings/sets'
import { loading, toggleSelectedItems } from '../../../../store/Settings/actions'
import { LOAD_ITEMS, putItems, putObjectToUsesOwn, removeObjectFromUsesOwn } from '../../../../store/Settings/actions/sets/Object/Background'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_ITEMS, worker)
}

function* worker({ payload }) {
    const { stateKey, required, afterAdded } = payload

    try {
        const __itemsLoaded = yield select(state => state.settings.settings[OBJECT_BACKGROUND][stateKey]?.__itemsLoaded)



        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedGroups = yield select(state => state.objectsGroup.selected || [])

        let usesOwn = false
        let fetchedItems = { error: false }

        if (selectedObjects.length || selectedGroups.length) {
            if (required || __itemsLoaded !== true) {
                yield put(loading({
                    type: OBJECT_BACKGROUND,
                    stateKey,
                    loading: true,
                }))

                //
                // Загрузка данных
                //

                // С проверкой на собственный фоновый контент
                if (selectedObjects.length === 1 && selectedGroups.length === 0) {
                    const objectId = selectedObjects[0]
                    const objectGroupId = yield select(state => state.objects.allObjectsAssociated[objectId].msgroup_id || 0)

                    fetchedItems = yield call(fetchItems, [objectId])


                    if (fetchedItems.error) {
                        throw new Error("Ошибка при загрузке собственного фонового контента.")
                    } else {
                        // Если собственного фонового контента нет, загружаем фоновый контент группы
                        if (fetchedItems.success?.length) {
                            usesOwn = true
                        } else {
                            if (Number(objectGroupId)) {
                                fetchedItems = yield call(fetchItems, [objectGroupId])
                            }
                        }
                    }
                }

                // Без проверки. Загрузка либо для группы, либо объекта
                else {
                    let msIds = selectedGroups.length ? selectedGroups : selectedObjects
                    fetchedItems = yield call(fetchItems, msIds)
                }

                //
                // Обработка данных
                //

                // Ошибка
                if (fetchedItems.error) {
                    yield put(showAlertError({
                        errors: [`Ошибка сервера при ${LOAD_ITEMS}.`, fetchedItems],
                        text: "Расписание не было загружено. Попробуйте повторить."
                    }))

                    // Успех
                } else {
                    let periods = {},
                        assocItems = {},
                        selectedPeriodStateKay

                    let selectedPeriod = yield select(state => state.settings.settings[OBJECT_BACKGROUND][stateKey]?.selectedPeriod)

                    if (!!!selectedPeriod)
                        selectedPeriod = -1


                    fetchedItems.success.map(item => {
                        let periodId = item.dates_id && item.dates_id !== "" ? item.dates_id : -1,
                            itemStateKey = generateStateKey([stateKey, item.id]),
                            periodStateKey = generateStateKey([stateKey, periodId])


                        if (periodId === selectedPeriod)
                            selectedPeriodStateKay = periodStateKey

                        if (!periods[periodStateKey]) {
                            periods[periodStateKey] = {}
                            periods[periodStateKey].list = []
                        }

                        assocItems[itemStateKey] = item
                        periods[periodStateKey].list.push(item)
                    })

                    if (usesOwn) {
                        yield put(putObjectToUsesOwn({ objectId: selectedObjects[0] }))
                    } else {
                        yield put(removeObjectFromUsesOwn({ objectId: selectedObjects[0] }))
                    }

                    yield put(putItems({
                        stateKey,
                        periods,
                        assocItems,
                    }))

                    if (afterAdded) {


                        let added = periods[selectedPeriodStateKay].list.reduce(function (prev, current) {
                            if (+current.id > +prev.id) {
                                return current;
                            } else {
                                return prev;
                            }
                        })


                        if (added) {
                            console.log({ added });



                            const itemIds = [added.id] || Object.key(added.success.id)

                            yield put(toggleSelectedItems({
                                type: OBJECT_BACKGROUND,
                                stateKey: stateKey,
                                itemIds: itemIds
                            }))
                        }

                    }
                }
            }
        }
    }

    catch (e) {
        console.log({ e });
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ITEMS}.`, e],
            text: "Расписание не было загружено. Попробуйте повторить."
        }))

    }

    finally {
        yield put(loading({
            type: OBJECT_BACKGROUND,
            stateKey,
            loading: false,
        }))
    }
}

function fetchItems(ms_id) {
    ms_id = ms_id.join(",")
    return axios.get("/msbox/play/select", { params: { ms_id } })
        .then(response => response.data)
}