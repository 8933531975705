import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import InputText from '../Form/InputText'
import InputPassword from '../Form/InputPassword'
import { cl } from '../../utilities/cl'
import { Button, Form, FormControl, FormLabel, InputGroup, Col, } from 'react-bootstrap'
// import { Button as ButtonBS, Form, FormControl, FormLabel, InputGroup, Col } from 'react-bootstrap'
import CarouselSlider from "./carousel2"
import SvgEye from "../SVG/Eye"
import Recovery from './recovery'
import { doLogin } from '../../store/Login/actions'
import Spinner from '../Spinner'


const LoginForm = props => {
    const className = cl(
        props.className,
        "Login",
    )
    let history = useHistory()
    useEffect(() => {        
        if (localStorage.sessionToken && localStorage.sessionToken !== '') {
            history.push('/main')
        }
        if (props.login.error !== '') {
            setLoginError(props.login.error)
        }

    }, [props.login])
    const [validated, setValidated] = useState(false);
    const [login, setLogin] = useState("")
    const [code, setCode] = useState("")
    const [password, setPassword] = useState("")
    const [forgetpass, setForgetpass] = useState(false)
    const [showpass, setShowpass] = useState(false)
    const [loginerror, setLoginError] = useState('')

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            props.doLogin({ login: login, password: password, code })
        }
        setValidated(true);
    };

    const handleShow = () => {
        setShowpass(!showpass)
    }

    const CLabelL = () => { return (<label className="Login-form__label">Введите ваш логин</label>) }
    const CLabelP = () => { return (<label className="Login-form__label" >Введите пароль</label>) }
    const CLabelC = () => { return (<label className="Login-form__label" >Введите код</label>) }

    useEffect(()=>{},[])


    return (
        <div className={className}>
            {/* <div className="Login-page__header">
                <svg width="260" height="44" viewBox="0 0 468 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path d="M46.3307 70.0875C61.4082 56.7708 62.9059 33.742 49.7256 18.6231C44.7331 12.916 38.4425 9.21139 31.7525 7.40913C43.3352 5.90726 55.4171 10.0124 63.7047 19.5243C76.9849 34.6432 75.4871 57.672 60.3098 70.9886C50.824 79.299 38.3427 81.8021 26.9597 78.7984C33.9492 77.8972 40.7391 74.9936 46.3307 70.0875Z" fill="#E6E7E8" />
                        <path d="M9.28614 51.7647C10.5842 31.6396 27.8584 16.4205 47.9283 17.7222C55.4171 18.2228 62.207 20.9262 67.7986 25.2315C61.9074 15.1189 51.3233 8.01002 38.7421 7.10889C18.6721 5.80726 1.39794 21.0263 0.0998821 41.1514C-0.698923 53.7672 4.89271 65.2816 14.1788 72.3905C10.6841 66.383 8.88674 59.2741 9.28614 51.7647Z" fill="#BBBDBF" />
                        <path d="M10.5842 31.8399C9.28615 51.9651 24.4634 69.2867 44.5334 70.5884C52.0222 71.089 59.1116 69.2867 65.2025 65.7823C58.0133 75.094 46.5304 80.8011 34.0491 79.9C13.9791 78.5984 -1.19817 61.2767 0.0998876 41.1516C0.898693 28.5358 7.98809 17.9225 18.073 12.0151C13.8793 17.522 11.0835 24.3305 10.5842 31.8399Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M44.3337 33.4417L40.3397 29.5369H32.4515L28.4574 33.4417V53.4668L32.4515 57.3717H40.3397L44.3337 53.4668V45.4568H37.1444V52.065H35.5468V34.8435H37.1444V40.3504H44.3337V33.4417Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.517 34.8435V57.3717H62.6063V29.5369H52.122L48.2279 33.4417L45.5319 57.3717H52.7211L54.818 34.8435H55.517Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M72.2919 42.2528H72.192L70.9938 29.5369H64.1041L68.4975 52.065H64.9029V57.3717H71.2934L75.5869 53.667L80.0802 29.5369H73.1905L72.2919 42.2528Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M98.0533 29.5369V40.5506H98.1532L101.947 29.5369H109.037L103.645 42.5531L109.736 57.3717H102.247L98.1532 45.9574H98.0533V57.3717H90.9639V45.9574H90.8641L86.7702 57.3717H79.2814L85.3723 42.5531L79.8805 29.5369H87.0697L90.8641 40.5506H90.9639V29.5369H98.0533Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M122.517 40.5506H118.023V34.8435H125.612V29.5369H110.834V57.3717H122.517L126.71 53.4668V44.4555L122.517 40.5506ZM119.621 52.065H118.023V45.9574H119.621V52.065Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M141.888 29.5369H131.004L127.21 57.3717H134.399L134.798 52.3654H137.295L137.694 57.3717H144.783L141.888 29.5369ZM134.898 47.0588L135.597 36.2453H135.997L136.795 47.0588H134.898Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M327.61 29.4368L324.315 40.5507V29.4368H317.126V57.2716H322.418L325.713 46.1577V57.2716H332.902V29.4368H327.61ZM309.637 42.3529L315.129 29.3367H307.939L304.145 40.3504H304.045V29.3367H296.856V57.1715H304.045V45.8573H304.145L308.538 57.2716H316.127L309.637 42.3529ZM261.409 29.4368H249.726V57.2716H256.816V47.7597H261.309L265.503 43.8548V33.2416L261.409 29.4368ZM258.413 42.4531H256.816V34.7434H258.413V42.4531ZM247.53 34.7434V29.4368H235.348V57.2716H247.53V51.965H242.537V45.2566H246.531V39.9499H242.537V34.7434H247.53ZM232.153 51.965V29.4368H221.668L217.674 33.3417L215.777 51.965H214.379V62.6783H220.57V57.2716H228.059V62.6783H234.25V51.965H232.153ZM225.063 51.965H223.066L224.364 34.7434H224.964V51.965H225.063ZM210.285 51.965V29.4368H199.801L195.807 33.3417L193.91 51.965H192.512V62.6783H198.703V57.2716H206.192V62.6783H212.382V51.965H210.285ZM203.196 51.965H201.199L202.597 34.7434H203.196V51.965ZM188.218 29.4368H180.23L176.236 33.3417V53.3667L180.23 57.2716H188.218L192.213 53.3667V33.3417L188.218 29.4368ZM185.023 51.965H183.426V34.7434H185.023V51.965ZM157.764 29.4368V57.2716H164.953V34.7434H166.551V57.2716H173.64V29.4368H157.764Z" fill="#EC1C24" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M289.667 42.3527L295.159 29.3365H289.467C288.568 30.4378 287.67 31.5392 286.871 32.6406L284.275 40.2501H284.175V29.2363H276.986V40.2501H276.886L275.887 37.5467C272.592 34.7432 269.996 31.9397 265.902 29.3365L271.394 42.3527L265.203 57.1712H272.792L276.087 47.9597L276.886 45.6568H276.986V50.0624C278.084 52.3652 279.182 54.7682 280.281 57.2714H280.88C281.878 54.4679 282.977 51.6644 284.175 48.961V45.8571H284.275L284.874 47.4591L288.369 57.2714H295.957L289.667 42.3527Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M316.726 0.100153C315.728 1.30165 314.629 2.50316 313.531 3.80478C306.741 11.9149 300.351 20.3254 294.759 29.3367C292.063 33.542 289.567 37.8473 287.27 42.353C286.372 44.0551 285.573 45.6571 284.774 47.3592C284.574 47.8599 284.375 48.3605 284.075 48.8611C282.877 51.5645 281.779 54.368 280.78 57.1715C280.78 57.2716 280.68 57.3717 280.68 57.4719C280.68 57.572 280.58 57.6721 280.48 57.8724C280.381 57.6721 280.281 57.4719 280.181 57.1715C279.083 54.6684 277.984 52.2654 276.886 49.9625C276.586 49.2616 276.287 48.6609 275.987 47.96C272.992 41.4518 269.996 35.3442 265.802 29.2366C269.896 31.8398 272.592 34.7435 275.788 37.4468C276.087 37.7472 276.486 38.0476 276.786 38.348C277.884 39.2491 278.983 40.0501 280.281 40.9512C281.479 39.3492 282.677 37.7472 283.875 36.1452C284.774 34.9437 285.673 33.7422 286.571 32.6408C287.47 31.5394 288.269 30.4381 289.167 29.3367C297.555 18.8236 306.342 8.71092 316.327 -0.100098C316.626 2.75031e-05 316.726 0.100153 316.726 0.100153Z" fill="#EC1C24" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M361.859 29.4368H345.983V57.2716H353.172V34.7434H354.67V57.2716H361.859V29.4368Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M376.537 29.4368H364.854V57.2716H371.944V47.8598H376.437L380.631 43.9549V33.3416L376.537 29.4368ZM373.541 42.453H371.944V34.7434H373.541V42.453Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M394.21 29.4368H386.322L382.328 33.3416V53.3667L386.322 57.2716H394.21L398.204 53.3667V33.3416L394.21 29.4368ZM391.115 51.8648H389.517V34.7434H391.115V51.8648Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M416.277 51.8648V29.4368H405.793L401.799 33.3416L399.902 51.9649H398.604V62.6783H404.795V57.2716H412.283V62.6783H418.474V51.8648H416.277ZM409.188 51.8648H407.191L408.489 34.7434H409.088V51.8648H409.188Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M433.951 29.4368H422.967L419.173 57.2716H426.262L426.662 52.2653H429.158L429.557 57.2716H436.647L433.951 29.4368ZM426.961 46.8585L427.66 36.045H428.06L428.859 46.8585H426.961Z" fill="#929497" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M456.317 29.4368H449.228V40.3504H449.128L445.334 29.4368H438.244L443.636 42.453L437.546 57.2716H445.034L449.128 45.8573H449.228V57.2716H456.317V45.8573H456.417L460.511 57.2716H468L461.909 42.453L467.401 29.4368H460.212L456.417 40.3504H456.317V29.4368Z" fill="#929497" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="468" height="80" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div> */}
            {!forgetpass ?
                <div className="Login-form">
                    <h2 className="Login-form__header">Добро пожаловать в мир технологий <span className="colortext">MSBOX</span> </h2>

                    {/* <ButtonBS style={ { marginTop: "40px" } }>Войти</ButtonBS> */}
                    {/* {!props.login.loading ? */}
                        <Form noValidate className="w-100" validated={validated} md="6" onSubmit={handleSubmit} >
                            <div className="form-groupp">
                                <CLabelL />

                                <input
                                    type="text"
                                    required
                                    placeholder="Логин"
                                    className="form-control"
                                    style={{ height: "60px", borderRadius: "10px" }}
                                    onChange={(e) => setLogin(e.target.value)}
                                    value={login}

                                />
                                <div className="invalid-feedback">
                                    Поле обязательно для заполнения
                                </div>

                            </div>

                            <div className="form-groupp">
                                <CLabelP />

                                <input
                                    type={showpass ? "text" : "password"}
                                    required
                                    placeholder="Пароль"
                                    className="form-control"
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ height: "60px", borderRadius: "10px" }}
                                    value={password}
                                />
                                {password.length > 0 && <SvgEye onClick={() => handleShow()} className={`FormInputPassword__icon-password eye ${showpass ? "eye-shown" : ""}`} />}
                                <div class="invalid-feedback">
                                    Поле обязательно для заполнения
                                </div>

                            </div>
                            {loginerror !== '' && <span className="wrong">Неверный логин или пароль. Проверьте правильность введенных Вами данных или воспользуйтесь формой восстановления пароля</span>}
                            {props.login.a2 === true && <>
                                <span className="wrong">Включена двухфакторная авторизация, нужен дополнительный код</span>
                                <div className="form-groupp">
                                    <CLabelC />
                                    <input
                                        type="text"
                                        required
                                        placeholder="Код"
                                        className="form-control"
                                        style={{ height: "60px", borderRadius: "10px" }}
                                        onChange={(e) => setCode(e.target.value)}
                                        value={code}

                                    />
                                    <div className="invalid-feedback">
                                        Поле обязательно для заполнения
                                    </div>

                                </div>
                            </>}
                            <span className="forget-password" onClick={() => setForgetpass(true)}>Забыли пароль?</span>
                            <button className="btn btn-md btn-primary  w-100 Login-btn" type="submit" disabled={props.login.loading }>Войти</button>
                            <p style={{ marginTop: "60px" }} ><span className="agreement">* Проходя авторизацию вы принимаете условия<br />
                                <a title="Пользовательское соглашение" href="agreement.html" target="_blank">«Пользовательского соглашения»</a></span>
                            </p>
                        </Form>
                         {/* :
                         <Spinner />
                     } */}
                </div >
                :
                <Recovery />
            }
            {/* <div className="Login-slider"> */}
            <CarouselSlider />
            {/* </div> */}
        </div >
    )
}

const stateToProps = state => ({
    login: state.login
})

const actionsToProps = {
    doLogin
}
export default connect(stateToProps, actionsToProps)(LoginForm)