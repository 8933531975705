export const DELETE_PLAYLISTS = "Playlists/DELETE_PLAYLISTS"
export const LOAD_PLAYLISTS = "Playlists/LOAD_PLAYLISTS"
export const LOAD_PLAYLIST = "Playlists/LOAD_PLAYLIST"
export const LOADING = "Playlists/LOADING"
export const PUT_PLAYLISTS = "Playlists/PUT_PLAYLISTS"

export const UPDATE_PLAYLIST = "Playlists/UPDATE_PLAYLIST"
export const UPDATE_PLAYLIST_ITEM = "Playlists/UPDATE_PLAYLIST_ITEM"

export const TOGGLE_SELECTED_PLAYLIST = "Playlists/TOGGLE_SELECTED_PLAYLIST"
export const TOGGLE_SELECTED_SONG = "Playlists/TOGGLE_SELECTED_SONG"
export const ADD_SONGS_TO_PLAYLIST = "Playlists/ADD_SONGS_TO_PLAYLIST"
export const INSERT_SONGS_TO_PLAYLIST = "Playlists/INSERT_SONGS_TO_PLAYLIST"
export const SORT_PLAYLISTS = "Playlists/SORT_PLAYLISTS"
export const SORT_SONGS = "Playlists/SORT_SONGS"
export const LOADING_AVMAP_BINDINGS = "Playlists/LOADING_AVMAP_BINDINGS"
export const PUT_AVMAP_BINDINGS = "Playlists/PUT_AVMAP_BINDINGS"
export const LOADING_AVMAP = "Playlists/LOADING_AVMAP"


export const loadPlaylists = () => ({
    type: LOAD_PLAYLISTS,
})

export const loadPlaylist = (id) => ({
    type: LOAD_PLAYLIST,
    payload: id
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})
export const loading_avmap = mode => ({
    type: LOADING_AVMAP,
    payload: mode
})

export const loadAvmapBindings = () => ({
    type: LOADING_AVMAP_BINDINGS
})
export const putAvmapBindings = avmap => ({
    type: PUT_AVMAP_BINDINGS,
    payload: avmap
})
export const deletePlaylists = selectedPlaylists => ({
    type: DELETE_PLAYLISTS,
    payload: selectedPlaylists
})

export const putPlaylists = playlists => ({
    type: PUT_PLAYLISTS,
    payload: playlists
})

export const updatePlaylist = playlist => ({
    type: UPDATE_PLAYLIST,
    payload: playlist
})

export const updatePlaylistItem = playlist => ({
    type: UPDATE_PLAYLIST_ITEM,
    payload: playlist
})

export const toggleSelectedPlaylist = ({id, selected, simple, required}) => ({
    type: TOGGLE_SELECTED_PLAYLIST,
    payload: {
        id,
        selected,
        simple,
        required,
    }
})
export const toggleSelectedSong = ({id_song, selected_song, onlyOne}) => ({
    type: TOGGLE_SELECTED_SONG,
    payload: {
        id_song,
        selected_song,
        onlyOne
    }
})
export const addSongToPlaylist = ({id_playlist, selected_song, songs}) => ({
    type: ADD_SONGS_TO_PLAYLIST,
    payload: {
        id_playlist,
        selected_song,
        songs
    }
})
export const sortPlaylists = sortSource => ({
    type: SORT_PLAYLISTS,
    payload: sortSource
})
export const sortSongs = sortSource => ({
    type: SORT_SONGS,
    payload: sortSource
})
