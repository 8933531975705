import { takeEvery, call, put, all } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { CREATE_ADS } from '../../../store/Modal/types/AdsCreate/actions'
import { toggleSelectedTrack } from '../../../store/Tracks/actions'
import { toggleSelectedGroup } from '../../../store/ObjectsGroup/actions'
import { toggleSelectedTags } from '../../../store/ObjectsByTags/actions'
import { toggleSelectedObject } from '../../../store/Objects/actions'
import { closeModal } from '../../../store/Modal/actions'
import { loadTracks } from '../../../store/Tracks/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'
import { loadingText, loading } from "../../../store/App/actions";
import { store } from "../../../connect";
import { reloadValue } from '../../../store/Search/actions'

export default function* () {
    yield takeEvery(CREATE_ADS, worker)
}

function* worker({ payload }) {
    // function* updateText(txt) {
    //     yield put(loadingText(txt))
    // }
    // yield updateText('Начинается загрузка файла')
    try {
        let createdSuccess = true
        let lastInsertId = 0
        let error = ''
        let selected = []
        if (typeof payload.list !== 'undefined' && payload.list.length >= 2) {
            const list = payload.list;
            let created = [];
            for (let [index, adsItem] of list.entries()) {
                payload.setVal(index, 'status', 1);
                try {
                    yield put(loading(true))
                    yield put(loadingText('Идет загрузка файла'))
                    const task = yield call(fetchCreate, {
                        ...adsItem,
                        setVal: payload.setVal,
                        index: index,
                        videolist_id: payload.videolist_id
                    });
                    if (task.error) {
                        payload.setVal(index, 'status', -1);
                        payload.setVal(index, 'error', task.error);
                    } else {
                        payload.setVal(index, 'status', 2);
                        selected.push(Number(task.success.lastInsertId))
                        lastInsertId = task.success.lastInsertId
                    }
                    created.push(task)
                } catch (e) {
                    payload.setVal(index, 'error', e);
                    payload.setVal(index, 'status', -1);
                }


            }
            created.map(d => {
                if (d.error) createdSuccess = false
            })

        } else {
            yield put(loading(true))
            yield put(loadingText('Идет загрузка файла'))
            let created = yield call(fetchCreate, payload)
            if (created.error) {
                createdSuccess = false
            } else {
                selected.push(Number(created.success.lastInsertId))
                lastInsertId = created.success.lastInsertId
            }
        }


        if (!createdSuccess) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_ADS}.`, {}],
                text: "Ролик не был создан. Попробуйте повторить."
            }))
            yield put(closeModal("adsCreate"))

        } else {
            yield put(toggleSelectedTrack({
                ids: selected
                // id: selected
                //id: Number(lastInsertId),
                //onlyOne: true
            }))
            yield put(toggleSelectedGroup({
                selected: []
            }))
            yield put(toggleSelectedTags({
                selected: []
            }))
            yield put(toggleSelectedObject({
                selected: [],
                simple: true
            }))
            yield put(closeModal("adsCreate"))
            const callback = () => reloadValue('tracks')
            yield put(loadTracks({callback}))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CREATE_ADS}.`, e],
            text: "Ролик не был создан. Попробуйте повторить."
        }))
    } finally {
        yield put(loading(false))
        yield put(loadingText(''))
    }
}

function fetchCreate(adsData) {
    let formData = new FormData()
    formData.set('filename', adsData.filename)
    formData.set('name', adsData.name)
    formData.set('videolist_id', adsData.videolist_id)
    formData.set('description', adsData.description);

    if (adsData.pri) formData.set('pri', adsData.pri)

    return axios(
        {
            url: "/clip/insert",
            method: "POST",
            data: formData,
            onUploadProgress: (progressEvent) => {
                const val = Math.ceil(progressEvent.loaded / progressEvent.total * 100);
                console.log(val);
                if (val >= 100) {
                    store.dispatch(loadingText(`[${adsData.name}]: Идет конвертация файла, это займет от 15 до 60 секунд, пожалуйста, не обновляйте страницу.`))
                } else {
                    store.dispatch(loadingText(`[${adsData.name}]: Загрузка файла: ${val}%`))
                }
                adsData.setVal && adsData.setVal(adsData.index, 'progress', val);
            }
        }
    )
        .then(response => response.data)
}