import {takeEvery, call, put} from 'redux-saga/effects'
import {buildGETUrl} from '../../../utilities/buildGETUrl'
import {SAVE_ADS} from '../../../store/Modal/types/AdsEdit/actions'
import {loadTracks} from '../../../store/Tracks/actions'
import {closeModal} from '../../../store/Modal/actions'
import {showAlertError} from '../../../store/Alert/actions'
import axios from 'axios'
import {loadingText, loading} from "../../../store/App/actions";
import {store} from "../../../connect";
import { reloadValue } from '../../../store/Search/actions'

export default function* () {
    yield takeEvery(SAVE_ADS, worker)
}

function* worker({payload}) {
    yield put(loading(true))
    yield put(loadingText('Идет загрузка файла'))
    try {
        let {id, dataToSave} = payload

        if(!!!payload.pri){
            delete payload.pri
        }
        const saved = yield call(fetchSave, id, dataToSave)

        if (saved.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${SAVE_ADS}.`, saved],
                text: "Ролик не был сохранён. Попробуйте повторить."
            }))
        } else {
            yield put(closeModal("adsEdit"))            
            yield put(loadTracks({callback: reloadValue}))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${SAVE_ADS}.`, e],
            text: "Ролик не был сохранён. Попробуйте повторить."
        }))
    } finally {
        yield put(loading(false))
        yield put(loadingText(''))
        

    }
}

function fetchSave(id, dataToSave) {
    let formData = new FormData()
    if (dataToSave && dataToSave.name) {
        Object.keys(dataToSave).forEach(name => {
            if (name !== "filename" || typeof dataToSave[name] !== "string") {
                formData.set(name, dataToSave[name] === null ? 'NULL' : dataToSave[name])
            }
        })
    }

    return axios(
        {
            url: `/clip/update?id=${id}`,
            method: "POST",
            data: formData,
            onUploadProgress: (progressEvent) => {
                const val = Math.ceil(progressEvent.loaded / progressEvent.total * 100);
                if (val >=100) {
                    store.dispatch(loadingText(`[${dataToSave.name}]: Идет конвертация файла, это займет от 15 до 60 секунд, пожалуйста, не обновляйте страницу.`))
                } else {
                    store.dispatch(loadingText(`[${dataToSave.name}]: Загрузка файла: ${val}%`))
                }
            }
        }
    )
        .then(response => response.data)
}