import React from 'react'
import { connect } from 'react-redux'

import VideolistItem from './VideolistItem'
import {loadVideolist} from "../../../store/ContentVideolists/actions";


const VideolistItemContainer = props => (
    <VideolistItem
        loadVideolist={props.loadVideolist}
        id={ props.id }
        name={ props.name }
        used={ props.used }
        totaltime={ props.totaltime }
        totalsize={ props.totalsize }
        totalcount={ props.totalcount }
        selected={ props.selected }
        markItems={ props.markItems }
        tags={ props.tags }
        variant={ props.variant }
    />
)

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.videolists.selected],
})

const actionsToProps = {
    loadVideolist
}

export default connect(stateToProps, actionsToProps)(VideolistItemContainer)