import { takeEvery, select, all, put, call } from 'redux-saga/effects'
import { OBJECT_SCHEDULE } from '../../../../store/Settings/sets'
import { loading, toggleSelectedItems } from '../../../../store/Settings/actions'
import {
    LOAD_ITEMS,
    SELECT_PERIOD,
    putItems,
    putObjectToUsesOwn,
    removeObjectFromUsesOwn
} from '../../../../store/Settings/actions/sets/Object/Schedule'
import { buildGETUrl } from '../../../../utilities/buildGETUrl'
import { parseBitmap, maps } from '../../../../utilities/bitmap'
import { generateStateKey } from '../../../../utilities/generateStateKey'
import { showAlertError } from '../../../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_ITEMS, worker)
    yield takeEvery(SELECT_PERIOD, worker)
}

function* worker({ payload }) {
    const { stateKey, itemIds = [], required, oldPeriodIds } = payload
    console.log('>>', { payload })

    try {
        const __itemsLoaded = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey]?.__itemsLoaded)
        const selectedObjects = yield select(state => state.objects.selected || [])
        const selectedGroups = yield select(state => state.objectsGroup.selected || [])

        let usesOwn = false
        let fetchedItems = { error: true }

        if (selectedObjects.length || selectedGroups.length) {
            if (required || __itemsLoaded !== true || itemIds.length) {
                yield put(loading({
                    type: OBJECT_SCHEDULE,
                    stateKey,
                    loading: true,
                }))

                //
                // Загрузка данных
                //

                // С проверкой на собственное расписание
                if (selectedObjects.length === 1 && selectedGroups.length === 0) {
                    // p.s. В данном случае нет заморочке с загрузкой только определённых блоков. Просто грузим всё

                    const objectId = selectedObjects[0]
                    const objectGroupId = yield select(state => state.objects.allObjectsAssociated[objectId].msgroup_id || 0)

                    fetchedItems = yield call(fetchItems, [objectId])

                    if (fetchedItems.error) {
                        throw new Error("Ошибка при загрузке собственного расписания.")
                    } else {
                        // Если собственного расписания нет, загружаем расписание группы
                        if (fetchedItems.success?.length) {
                            usesOwn = true
                        } else {
                            if (Number(objectGroupId)) {
                                fetchedItems = yield call(fetchItems, [objectGroupId])
                            }
                        }
                    }
                }

                // Без проверки. Загрузка либо для группы, либо объекта
                else {
                    const msIds = selectedGroups.length ? selectedGroups : selectedObjects

                    // Для конкретных блоков
                    if (itemIds.length) {
                        fetchedItems = yield call(fetchItems, itemIds)
                        // fetchedItems = yield all(
                        //     itemIds.map(itemId => call(fetchItems, [itemId]))
                        // )
                        //
                        // let buildedItems = []
                        // fetchedItems.map(resp => {
                        //     buildedItems.push(resp.success[0])
                        // })
                        //
                        // fetchedItems = {
                        //     success: buildedItems
                        // }
                    }
                    // Загружаем всё, что есть
                    else {
                        fetchedItems = yield call(fetchItems, msIds)
                    }
                }

                //
                // Обработка данных
                //

                let newPeriodIds = [],
                    toSelected = [];

                // Ошибка
                if (fetchedItems.error) {
                    yield put(showAlertError({
                        errors: [`Ошибка сервера при ${LOAD_ITEMS}.`, fetchedItems],
                        text: "Расписания не были загружены. Попробуйте повторить."
                    }))
                }

                // Успех
                else {
                    const freePeriodStateKey = generateStateKey([stateKey, -1])

                    let items = fetchedItems.success,
                        blocks = {},
                        periods = {},
                        assocItems = {}

                    let selectedPeriod = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey]?.selectedPeriod)


                    if (!!!selectedPeriod)
                        selectedPeriod = -1


                    const _selectedPeriodStateKey = generateStateKey([stateKey, selectedPeriod])

                    let statePeriods = yield select(state => state.settings.settings[OBJECT_SCHEDULE][stateKey][_selectedPeriodStateKey])


                    if (statePeriods)
                        statePeriods = statePeriods.list
                    else
                        statePeriods = []


                    items.map(item => {
                        const itemStateKey = generateStateKey([stateKey, item.id])
                        item.start_id = parseIds(item.start_id)
                        item.stop_id = parseIds(item.stop_id)


                        const parsedDaymask = parseBitmap(item.daymask, maps.daymaskWidthAds)
                        const withoutAds = parsedDaymask.indexOf(128) !== -1

                        item["__without-ads"] = withoutAds
                        if (withoutAds) item.daymask = item.daymask - 128

                        item.insideStart = item.start_id[1]
                        item.insideStop = item.stop_id[1]
                        item.outsideStart = item.start_id[0]
                        item.outsideStop = item.stop_id[0]

                        const shortTime = (item.starttime || "").slice(0, -3)
                        let blockNumber = shortTime.slice(3, 5)
                        blockNumber = (blockNumber / 5) + 1

                        item.__starttime = shortTime
                        item.__blocknumber = blockNumber

                        if (!blocks[blockNumber]) blocks[blockNumber] = []
                        blocks[blockNumber].push(item.id)

                        assocItems[itemStateKey] = item
                        // console.log({ selectedPeriod })

                        let _periodStateKey;

                        // Сортировка по датам
                        if (Number(item.dates_id) > 0) {
                            const periodStateKey = generateStateKey([stateKey, item.dates_id])
                            if (!periods[periodStateKey]) {
                                periods[periodStateKey] = {}
                                periods[periodStateKey].list = []
                            }
                            periods[periodStateKey].list.push(itemStateKey)

                            _periodStateKey = periodStateKey

                            // Сортировка без дат
                        } else {
                            if (!periods[freePeriodStateKey]) {
                                periods[freePeriodStateKey] = {}
                                periods[freePeriodStateKey].list = []
                            }

                            periods[freePeriodStateKey].list.push(itemStateKey)

                            _periodStateKey = freePeriodStateKey;

                        }




                        if (_selectedPeriodStateKey === _periodStateKey)
                            newPeriodIds.push(generateStateKey([stateKey, item.id]))



                    })


                    if (oldPeriodIds)
                        toSelected = newPeriodIds.filter(id => oldPeriodIds.indexOf(id) === -1)



                    if (usesOwn) {
                        yield put(putObjectToUsesOwn({ objectId: selectedObjects[0] }))
                    } else {
                        yield put(removeObjectFromUsesOwn({ objectId: selectedObjects[0] }))
                    }

                    yield put(putItems({
                        stateKey,
                        periods,
                        assocItems,
                        blocks,
                    }))
                }


                if (toSelected.length !== 0) {

                    toSelected = toSelected.map(stateKeyID => {

                        let arr = stateKeyID.split('-')
                        return Number(arr[arr.length - 1])
                    })



                    yield put(toggleSelectedItems({
                        type: OBJECT_SCHEDULE,
                        stateKey,
                        itemIds: toSelected
                    }))
                }



            }
        }
    }

    // Ошибка
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ITEMS}.`, e],
            text: "Расписания не были загружены. Попробуйте повторить."
        }))
    }

    finally {
        yield put(loading({
            type: OBJECT_SCHEDULE,
            stateKey,
            loading: false,
        }))
    }
}

function fetchItems(ms_id, id = -1) {
    ms_id = ms_id.join(",")

    let params = {
        ms_id
    }

    if (id !== -1) {
        params.id = id
    }

    return axios.get("/msbox/clip/select", { params: { ...params } })
        .then(response => response.data)
}

function parseIds(str) {
    str = str.replace("{", "")
    str = str.replace("}", "")
    str = str.split(",")
    if (!isNaN(str[0])) str[0] = Number(str[0])
    if (!isNaN(str[1])) str[1] = Number(str[1])
    return str
}