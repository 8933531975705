import React, { useCallback, useEffect, useMemo, useState } from 'react'

import ScrollContainer from '../../ScrollContainer'
import Spinner from '../../Spinner'
import ObjectItem from '../ObjectItem'
import Empty from '../Empty'


const Left = ({
    searchActive,
    searchFounded = [],
    objects = [],
    loading,
    toggleSelected,
}) => {
    if (searchActive) objects = [...searchFounded]

    const list = React.useMemo(() => {
        return (
            <ScrollContainer className="Objects__container-list" size="auto">
                {(objects.length || searchActive)
                    ? objects.map(object =>
                        <ObjectItem
                            //obj={object}
                            key={object.id}
                            id={object.id}
                            name={object.name}
                            address={object.address}
                            fullName={object.fullName}
                            mute={object.mute}
                            statusText={object.statusText}
                        />
                    )
                    : <Empty title="Объектов пока нет." />
                }
            </ScrollContainer>
        )
    }, [objects, searchActive])

    return (
        <section className="ObjectsLeft Objects__place">
            <div className="Objects__title">Объекты</div>
            <div className="Objects__list" onClick={toggleSelected}>
                {loading && <Spinner className="pt-xs pb-xs" />}
                {list}
            </div>
        </section>
    )
}

export default Left