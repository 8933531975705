import { DO_LOGOUT } from "../Login/actions";
import { SAVE_PV_DATA } from "./actions";

const initialState = {
    mute: false,
    note: ''
}


const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_PV_DATA:
            return { ...state, ...payload }

            case DO_LOGOUT:
                return initialState;

        default:
            return state;
    }
}

export default reducer