import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { CREATE_VIDEOLIST, loading } from '../../../store/Modal/types/VideolistCreate/actions'
import { closeModal } from '../../../store/Modal/actions'
import { loadVideolists } from '../../../store/ContentVideolists/actions'
import { showAlertError } from '../../../store/Alert/actions'
import axios from 'axios'
import { loadVideolistsAll } from '../../../store/ContentAllVideolists/actions'

export default function* () {
    yield takeEvery(CREATE_VIDEOLIST, worker)
}

function* worker({ payload }) {
    yield put(loading(true))
    try {
        let payload_clear = { ...payload }
        delete payload_clear.default
        const created = yield call(fetchCreate, payload_clear)

        if (created.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CREATE_VIDEOLIST}.`, created],
                text: "Видеолист не был создан. Попробуйте повторить."
            }))
            yield put(closeModal("videolistCreate"))

        } else {
            if (payload.default) {
                yield call(fetchDefaultForObjects, { videolist_id: created.success.lastInsertId })
            }
            yield put(closeModal("videolistCreate"))
            yield put(loadVideolists())
            yield put(loadVideolistsAll())
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CREATE_VIDEOLIST}.`, e],
            text: "Видеолист не был создан. Попробуйте повторить."
        }))
    }
    finally {


        yield put(loading(false))
        yield put(closeModal("videolistCreate"))
    }
}

function fetchCreate(videolistData) {
    let formData = new FormData()
    if (videolistData && videolistData.name) {
        Object.keys(videolistData).forEach(name => {
            formData.set(name, videolistData[name])
        })
    }

    return fetch(
        buildGETUrl("videolist/insert"),
        {
            method: "POST",
            body: formData
        }
    )
        .then(response => response.json())
}
function fetchDefaultForObjects(bindingData) {
    let formData = new FormData()
    if (bindingData && bindingData.videolist_id) {
        Object.keys(bindingData).forEach(name => {
            formData.set(name, bindingData[name])
        })
    }
    return axios(
        {
            url: "/msbox/update",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}