import { DO_LOGOUT } from '../Login/actions'
import { MODE_ACTIVE_ON, MODE_ACTIVE_OFF, CHANGE_VALUE, PUT_FOUNDED, CLEAR_VALUE } from './actions'

const initialState = {
    "tracks": {
        modeActive: false,
        value: "",
        founded: []
    },
    "objects": {
        modeActive: false,
        value: "",
        founded: []
    },
    "playlists": {
        modeActive: false,
        value: "",
        founded: []
    },
    "songs": {
        modeActive: false,
        value: "",
        founded: []
    },
    lastUpdated: ""
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case MODE_ACTIVE_ON:

        case MODE_ACTIVE_OFF:
            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    modeActive: payload.mode
                }
            }

        case CHANGE_VALUE:
            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    value: payload.value
                }
            }
        case CHANGE_VALUE:
            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    value: payload
                }
            }

        case PUT_FOUNDED:
            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    founded: payload.founded
                },
                lastUpdated: payload.type
            }

            case DO_LOGOUT:
                return initialState;    
    }
    return state
}

export default reducer