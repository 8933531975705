import React, { useContext, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import iconUser from '../../assets/icons/user.svg'
import iconLogOut from '../../assets/icons/log_out.svg'
import { useHistory } from 'react-router-dom';
import Select from "../Form/Select";
import { doSubLogin, doLogoutAction } from "../../store/Login/actions";
import { updateAccount } from "../../store/Roles/actions";
import SvgClose from "../SVG/Close";
import SvgDownload from "../SVG/Download";
import SvgServerSearch from "../SVG/ServerSearch";
import { openModal } from "../../store/Modal/actions";
import { searchObjects } from "../../store/Objects/actions";
import { BsFillVolumeMuteFill } from 'react-icons/bs';

const User = (props) => {
    let history = useHistory()
    const handleLogout = () => {
        props.doLogoutAction({navigateToLogin});
        // document.cookie = "PHPSESSID=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        // localStorage.sessionToken = "";
        // localStorage.removeItem('master')
        // //localStorage.token = "";
        // history.push('/login');
        // window.location.reload()
    }
    const user = props.user
    const master = props.master
    const mute = props.mute
    const featuresNote = props.featuresNote
    const [searchWord, setSearchWord] = useState("")
    const [searchId, setSearchId] = useState("")
    const fileRef = useRef(null)
    const navigateToLogin = () => history.push('/login')

    const hChangeUser = (selected) => {
        //console.log(selected)
        if (selected.value !== master.id) {
            props.doSubLogin({ id: selected.value })
        } else {
            window.localStorage.setItem("sessionToken", master.token);
            window.location.reload();
        }
    }

    const resetLogo = () => {
        const file = fileRef.current.files[0]
        if (file) {
            fileRef.current.value = ''
        }
        props.updateAccount({ logo: '' })
    }

    const hChangeLogo = () => {
        const file = fileRef.current.files[0]
        if (file) {
            props.updateAccount({ logo: file })
        }
    }

    const hOpenSearch = () => {
        props.openModal({
            type: "oneInput",
            size: "sm",
            data: {
                title: "Перейти к объекту",
                required: true,
                input: {
                    label: "ID объекта",
                    attrs: {
                        //value: searchId,
                        required: true
                    }
                },
                buttons: {
                    prim: {
                        title: "Перейти",
                        attrs: {
                            //disabled: !searchId
                        },
                        onClick: (value) => props.searchObjects(value)
                    }
                }
            }
        })
    }

    return (
        <div className="Navbar__User">
            <div className="wrap">

                <div className="account">
                    <div className="account-logo">
                        <img src={(user && user.logo) || iconUser} />
                        <label className="input-file account-logo-btn" title="Загрузить">
                            <input type="file" name="logo-file" ref={fileRef} accept="image/png, image/jpeg"
                                onChange={hChangeLogo} />
                            <SvgDownload variant="dark" size="sm1" title="Загрузить" />
                        </label>
                        <button className="close account-logo-btn" onClick={resetLogo} title="Удалить">
                            <SvgClose variant="light" size="sm1" title="Удалить" />
                        </button>
                    </div>


                    {master && master.subUsers.length ?
                        <Select
                            selected={user.id}
                            type="options"
                            search={true}
                            searchWord={searchWord}
                            onChange={hChangeUser}
                            options={[
                                { title: master.name, value: master.id, note: !!featuresNote?.length ? featuresNote : null },
                                ...master.subUsers.map((su) => ({ title: su.name, value: su.owner_id }))
                            ]}
                        ></Select> :
                        <span>{user?.name}</span>}
                </div>
                {mute &&
                    <div className="account">
                        <BsFillVolumeMuteFill size="25px" color='#ff3347' title='Звук отключен на учетной записи' />
                    </div>
                }
                {user && master ? <SvgServerSearch variant="dark" className="mr-sm cursor" size="sm" onClick={hOpenSearch} /> : ''}

                <div className="logout" onClick={() => handleLogout()}>
                    <a href="#">
                        <img src={iconLogOut} alt="Выйти из аккаунта" />
                    </a>
                </div>

            </div>
        </div>
    )
}

const stateToProps = state => ({
    user: state.login.user,
    master: state.settings_roles.master,
    featuresNote: state.features.note,
    mute: state.features.mute,
})

const actionsToProps = {
    doSubLogin,
    doLogoutAction,
    openModal,
    searchObjects,
    updateAccount
}
export default connect(stateToProps, actionsToProps)(User)
